/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.repeater";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uae = require("./templates/ua.element.js");

var required = ["data", "plate"];
exports.required = required;

exports.define = {
  supportServer: false,
  namespace: namespace,
  description: "Renders a repeatable element by the provided data.",
  title: "Repeat",
  fields: [{
    "data": {
      type: "array", 
      description: "The data to repeat the element with.",
      required: true
    }, 
    "inner": {
      type: "inner",
      description: "The template to repeat.",
      required: true
  }}],
  required: required
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);

  // console.log("Repeat Input", {
  //   inner: options.inner.inner + "",
  //   });


  var rows = [];

  //Passthrough the data provided as a row of data {};
  if (Array.isArray(options.data)) {
  
      
      for (var i = 0; i < options.data.length; i++) {

        var row = options.data[i];
        // console.log("Repeater Working Row", {
        //   row: row,
        //   inner: options.inner
        // });

        var rowRender = await addItem(options.inner, row);
        // console.log("Row Render", rowRender);
        rows.push(rowRender);

      }

  } else {

    //provide the context to the inner template.
    rows.push(await addItem(options.inner, options.data));

  }

  // console.log("Repeat Output", {
  //   rows: rows, 
  //   row1: rows[0]
  // });

  // console.log("The repeater rows", rows);

  //No need to wrap it as an array will generate a document fragment.
  // if (bybass) {
  //   return rows;
  // } else {
    return await jsonRender.render(rows);
  // }

}

async function addItem(inner, data) {

  // console.log("Adding Item", {
  //   inner: inner,
  //   data: data
  // });

  return await jsonRender.recursivelyHandleLiterals(inner, data);
}

/** 
{
  "n": "h1",
  "i": "${title}"
}
*/
