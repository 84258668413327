/**
 * JSON Render for UA Tools.
 * 
 * Built for The Universe.
 * 
 * (C) 2023 Justin K Kazmierczak, All Rights Reserved.
 */
var namespace = "ua.render";
var f = require("../scripts/f.js");

var registry = require("../../../uam/registry.js")("boilerplate");
var getProperty = require("../../../uam/functions/getProperty.js").function;
var convertShortHand = require("../../../uam/functions/shorthand.js").function;

/**
 * Attaches the ua rendering object registry to the rendering interface.
 */
var uae = require("./element.js");

/**
 * Attaches the ua rendering object registry to the rendering interface.
 * @param {*} _registry The ua rendering object registry.
 */
function init(_registry) {
    registry = _registry;
    uae.init(_registry);
} module.exports.init = init;

/**
 * Connects short hand to the full name.
 * Will auto convert the DOM Attribute to the first item in the array.
 */
var shorthand = {
    "class": ["c"],
    "namespace": ["n", "ns"],
    "inner": ["i"],
    "style": ["s"],
    "alt": ["a"],
    "title": ["t"]
};

/**
 * Converts DOM to JSON Object
 * @param {*} dom A dom object.
 * @returns The JSON object.
 */
function convertDOM(dom) {

    if (!(f.isDomElement(dom))) {
        throw new Error("The object is not a DOM element.");
    }

    var obj = {};

    if ("namespace" in shorthand) {
        obj[shorthand.namespace[0]] = dom.tagName.toLowerCase();
    } else {
        obj.namespace = dom.tagName.toLowerCase();
    }

    // obj.attributes = {};
    for (var i = 0; i < dom.attributes.length; i++) {
        var attr = dom.attributes[i];

        if (attr.name in shorthand) {
            attr.name = shorthand[attr.name][0];
        }

        //try to convert the value to a json object
        var value = attr.value;
        try {
            value = JSON.parse(attr.value);

        } catch (e) {
            // obj[attr.name] = attr.value;
        }

        obj[attr.name] = value;
    }

    obj.inner = [];

    for (var i = 0; i < dom.childNodes.length; i++) {
        var node = dom.childNodes[i];
        if (node.nodeType === Node.TEXT_NODE) {
            // Text node: push the text content as a string to the "inner" array
            // console.log("Text Node", node);
            
            if (node.textContent.trim().length > 0) {
                obj.inner.push(node.textContent);
            }

        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Element node: recursively call convertDOM and push the resulting object to the "inner" array
            obj.inner.push(convertDOM(node));
        } else if (node.nodeType === Node.ENTITY_REFERENCE_NODE) {
            //preserve the entity reference &nbsp; &amp; etc.
            obj.inner.push("&" + node.nodeName + ";");
        } else {
            // Something else (e.g. a comment): ignore it

        }
    }

    return obj;
} module.exports.convertDOM = convertDOM;


//Ok let's test this out
var obj = {
    n: "div",
    c: "test",
    i: [{
            n: "h1",
            i: "Example"
        },
        {
            n: "p",
            i: "Hello World"
        }]
};

// obj = convertShortHand(obj, shorthand);
// var dom = convertJSONToDOM(obj);
// console.log(dom);

// var main = document.querySelector("main");
// main.innerHTML = "";
// main.appendChild(dom);

// console.log("Inner html generated by dom", main.innerHTML);

/**
 * Converts the provided object to UA JSON (for html) standard shorthand.
 * @param {*} obj The object to convert.
 * @returns The converted object.
 */
function convertSH(obj) {
    return convertShortHand(obj, shorthand);
} module.exports.convertSH = convertSH;

/**
 * Converts JSON to a dom element.
 * @param {*} json Converts JSON to a Dom Element.
 * @param {*} options Options for rendering the JSON object.
 * @property {Object} options.PassthroughOptions Options to pass through to the DOM Element. Anything but "inner" and "namespace" will passthrough, you can narrow this down by providing a narrowed down opions object. Will join classes BUT the attributes provided by the json object will overide passthrough options.
 * @property {array} options.PassthroughExcept An array of properties to ignore in the passthrough.
 * @property {Object} options.context The context to use for the literals, if not provided, eval will be used. WARNING: This will enable code injection.
 * @property {Boolean} options.BypassShortHand If true, will not convert the shorthand to the full name.
 * @property {Boolean} options.noPlaceholder If true, will not render a placeholder for the object.
 * @property {String} firstNamespace The first namespace to use for reporting purposes.
 * @returns {HTMLElement} The DOM element.
 */
async function convertJSONToDOM(json, options = {}, firstNamespace = "$first") {  

    //check ig the json is a node 
    // if (f.isDomElement(json)) {
    //     return json;
    // }

    var PassthroughOptions = options.PassthroughOptions ? options.PassthroughOptions : null;
    var context = options.context ? options.context : undefined;
    var BypassShortHand = options.BypassShortHand ? options.BypassShortHand : false;

    // console.log("Shall I passthrough", PassthroughOptions);

    //if the json is a string clone it, array clone it, object clone it
    //Sanitize the json object so we don't modify the original
    if (typeof json === "string") {
        json = json.toString();
    } else if (Array.isArray(json)) {
        json = [...json];
    } else if (typeof json === "object") {


        if ("nodeType" in json) {

            var $html = document.createElement("div");
            $html.appendChild(json);
            $html = $html.innerHTML;

            throw new Error(`The object provided is already a DOM object and can not be interpreted as a JSON object. Complete HTML: ${$html}`);
        }

        json = {...json};

    } //else {

    if (!BypassShortHand) {
        json = convertShortHand(json, shorthand);
    }

    if (!BypassShortHand) {
        PassthroughOptions = convertShortHand(PassthroughOptions, shorthand);

        // if options.PassthroughExcept is set, remove matching properties from PassthroughOptions
        if (options.PassthroughExcept) {
            
            // console.log("PassthroughExcept Starting", {
            //     PassthroughOptions: PassthroughOptions,
            //     PassthroughExcept: options.PassthroughExcept
            // })

            for (var i = 0; i < options.PassthroughExcept.length; i++) {

                //check if the property is in the PassthroughOptions
                if (options.PassthroughExcept[i] in PassthroughOptions) {
                    //remove the property
                    delete PassthroughOptions[options.PassthroughExcept[i]];
                }
            }

            // console.log("PassthroughExcept Results", {
            //     PassthroughOptions: PassthroughOptions
            // });

        }

    }

    // console.log("Shorthand conversion", {
    //     json: json,
    //     njson: njson
    // });

    return await convertJSONToDOM_Recursion(json, PassthroughOptions, context, null, firstNamespace, options);
} module.exports.convertJSONToDOM = convertJSONToDOM;
module.exports.render = convertJSONToDOM;

/**
 * Converts JSON to HTML
 * @param {*} json The json to convert
 * @param {*} options the options to use.
 * @returns {string} The HTML string.
 */
async function convertJSONToHTML(json, options = {}) {
    var div = document.createElement("div");
    div.appendChild(await convertJSONToDOM(json, options));
    return div.innerHTML;
} module.exports.convertJSONToHTML = convertJSONToHTML;

/**
 * Converts JSON Object to DOM element
 * @param {*} jsonObj A JSON object representing a DOM element.
 * @param {*} PassthroughOptions Options to pass through to the DOM Element. Anything but "inner" and "namespace" will passthrough, you can narrow this down by providing a narrowed down opions object. Will join classes BUT the attributes provided by the json object will overide passthrough options.
 * @param {*} context The context to use for the literals, if not provided, eval will be used. WARNING: This will enable code injection.
 * @param {*} previous The previous object in the recursion for reporting purposes.
 * @param {*} lastNamespace The last namespace (ua tools) for reporting purposes.
 * @param {*} options The options to use.
 * @returns {HTMLElement} The DOM element created from the JSON object.
 */
async function convertJSONToDOM_Recursion(jsonObj, PassthroughOptions = null, context = null, previous = null, lastNamespace = null, options = {}) {

try {
    
    // console.log("Rendering", {
    //     $type: typeof jsonObj,
    //     $array: Array.isArray(jsonObj),
    //     ...jsonObj
    // });

    // if (typeof jsonObj !== "object" || jsonObj === null) {
    //     throw new Error("Invalid JSON object.");
    // }

    //check if it's a dom
    if (f.isDomElement(jsonObj)) {
        // If it's a dom, it's probably already rendered
        // return jsonObj;
        throw new Error("A dom object can not be converted back into a dom object.");
    }

    // handle text objects
    if ((typeof jsonObj === "string") || (typeof jsonObj === "number") 
        || (typeof jsonObj === "boolean")) {

        // console.log("output string", jsonObj)
         //suupirts &nbsp; &amp; etc.
         if (jsonObj == ">br") {
            // Line break node
            return await convertJSONtoDOM_Return(document.createElement("br"), null, "br");

        } else {

            //convert all html entities to their character
            return await convertJSONtoDOM_Return(document.createTextNode(decodeHtml(handleLiterals(jsonObj, context))), {
                context: context
            }, "textNode");
        }

    // if I'm an array and not an object
    //am I an object?
   
    } else if (Array.isArray(jsonObj)) {

        // console.log("Working with Array,", jsonObj)

        // var div = document.createElement("div");
        var frag = document.createDocumentFragment();

        // console.log("The object is an array.", jsonObj);
        for (var i = 0; i < jsonObj.length; i++) {

            var potentialRepo = {};

            try {
                potentialRepo = await convertJSONtoDOM_Return(await convertJSONToDOM_Recursion(jsonObj[i], null, null, jsonObj), null, "array", lastNamespace);
                frag.appendChild(potentialRepo, null, context)
            } catch (error) {

                if (potentialRepo === undefined) {
                    console.log("potentialRepo is undefined", {
                        jsonObj: jsonObj,
                        potentialRepo: potentialRepo,
                        context: context
                    });
                }
                
                frag.appendChild(await uae.CreateError("document.fragement", error, {
                    jsonObj: jsonObj,
                    potentialRespoinse: potentialRepo,
                    previous: previous,
                    lastNamespace: lastNamespace
                }));
            }


        }

        // console.log("output array", frag);

        // frag.childNodes.forEach((node) => {
        //     console.log(node);
        //   });

        return convertJSONtoDOM_Return(frag, null, "documentFragment");
    } else if (typeof jsonObj === "object") { 
        
        if (!("namespace" in jsonObj)) {

        

        // //create an error dom
        // var element = alert.render({
        //     icon: "!default",
        //     alertclass: "alert-danger",
        //     title: "Universe App Tools",
        //     inner: [{
        //         n: "p",
        //         i: `JSON object must have a 'namespace' property.`
        //     }, {
        //         n: "p",
        //         i: ["The developer is using The Universe App Tools to provide a better user experience.",
        //         {
        //             n: "a",
        //             i: "Learn more about The Universe App Tools.",
        //             href: "https://egtuniverse.com/"
        //         }
        //     ]
        //     }, {
        //         n: "p",
        //         i: "Details in the log."
        //     }]
        // });
        // console.error("JSON object must have a 'namespace' property.", {
        //     $type: typeof jsonObj,
        //     $array: Array.isArray(jsonObj),
        //     object: jsonObj
        // });
        // throw new Error("JSON object must have a 'namespace' property.");
        // return element;

        // throw new Error("JSON object must have a 'namespace' property.");
        
        } else {

            // var tagName = jsonObj.namespace;
            var domElement = document.createElement(jsonObj.namespace);

            //is the namespace registered in the registry?
            var element = registry.search(jsonObj.namespace);

            if (element) {

                //Render a UA Element
                domElement = await uae.RenderElement(jsonObj, {
                    previous: previous,
                    lastNamespace: lastNamespace
                }, options);

                // // if the function never returned anything
                // if (domElement === undefined) {
                //     // console.log("The element was undefined", {
                //     //     jsonObj: jsonObj,
                //     //     previous: previous,
                //     //     lastNamespace: lastNamespace
                //     // });

                //     //create an error dom
                //     domElement = await uae.CreateError(jsonObj.namespace, new Error(`The namespace ${jsonObj.namespace} never returned a value.`), {
                //         previous: previous,
                //         jsonObj: jsonObj,
                //         lastNamespace: lastNamespace
                //     });

                // }

            } else {
                //This should be a normal or unsuported namesapce

                //does the namespace have a "dot" in it
                if (jsonObj.namespace.indexOf(".") > -1) {

                    console.warn(`The namespace ${jsonObj.namespace} is not (yet) registered.`, {
                        namespace: jsonObj.namespace,
                        searchTest: registry.search(jsonObj.namespace),
                        allRegistered: registry.listRegistered()                    
                    });

                    var errorP = `The namespace ${jsonObj.namespace} is not (yet) registered.`;

                    if (window.UATisServer) {
                        errorP = `The namespace ${jsonObj.namespace} is not (yet) registered or is not compatible with server side rendering.`;
                    }

                    //create an error dom

                    domElement.appendChild(await uae.CreateError(jsonObj.namespace, new Error(errorP), {
                        previous: previous,
                        jsonObj: jsonObj,
                        lastNamespace: lastNamespace
                    }));

                } 
        

                for (var key in jsonObj) {
                    if (key === "namespace" || key === "inner") {
                        continue;
                    }

                    var value = jsonObj[key];
                    
                    if ((typeof value === "object") && !(Array.isArray(value))) {
                        // Recursively convert nested JSON objects
                        value = await convertJSONToDOM_Recursion(value, null, context, jsonObj, lastNamespace);
                    }

                    if (PassthroughOptions && (key in PassthroughOptions)) {
                        // add the passthrhough if it's class join through otherwise ignore it
                        if (key == "class") {
                            value += ` ${PassthroughOptions[key]}`;
                        }

                        if (key == "style") {
                            value += `; ${PassthroughOptions[key]}`;
                        }

                    }

                    domElement.setAttribute(key, value);

                }

                //Passthrough Options
                // if the option or attribute is already set do not add the passthrough
                // UA options overide passthrough options
                if (PassthroughOptions) {
                    for (var key in PassthroughOptions) {
                        if (key === "namespace" || key === "inner") {
                            continue;
                        }

                        //if the key is in the Passthrough Options

                        var value = PassthroughOptions[key];

                        if ((typeof value === "object") && !(Array.isArray(value))) {
                            // Recursively convert nested JSON objects
                            // value = await convertJSONToDOM_Recursion(value, null, context);
                            continue;
                        }

                        if (!(key in jsonObj)) {
                            //if the key is not in the json object
                            domElement.setAttribute(key, value);

                        }


                        if (key == "id") {
                            domElement.id = value;
                        }

                    }
                }

                if (jsonObj.inner) {
                    //recursion starts
                    var $rtn = await convertJSONToDOM_Recursion(jsonObj.inner, null, context, jsonObj, lastNamespace);

                    //if the $rtn is a dom element, append it
                    //if it is not replace the current property to allow the 
                    //top namespace to be replaced.
                    // console.log("inner", {
                    //     isDom: f.isDomElement($rtn),
                    //     rtn: $rtn
                    // });
                    // if (f.isDomElement($rtn)) {
                        domElement.appendChild($rtn)
                    // } else {
                    //     jsonObj.inner = $rtn;
                    // }
                }
            }

            return convertJSONtoDOM_Return(domElement, {}, jsonObj.namespace);
        } 
    } else {
        throw new Error(`jsonProp Unknown type ${typeof jsonObj}`);
    }

} catch (error) {

    var _namespace = jsonObj.namespace ? jsonObj.namespace : "unknown";

    //If create Error breaks, you'll get into a loop!
    return await convertJSONtoDOM_Return(await uae.CreateError(_namespace, error, {
        jsonObj: jsonObj,
        previous: previous,
        lastNamespace: lastNamespace
    }), {}, ".fullReachOf()");

}

} module.exports.convertJSONToDOM = convertJSONToDOM;

/**
 * Passes jsonDOM properties from one object to another.
 * Class will be appeneded. Ignores those in the ignore list.
 * @param {*} from the object providing the properties.
 * @param {*} to the object recieving the properties.
 * @param {*} ignore the properties to ignore. Will always ignore "namespace".
 * @returns The object with the properties passed.
 */
function pass(from, to, ignore = ["namespace"]) {

    try {
        
        // ensure namespace 
        // console.info(`Passing`, {
        //     from: from,
        //     to: to,
        //     ignore: ignore
        // });

        var from = {...from};
        var to = {...to};

        //shorthand on from and to
        from = convertShortHand(from, shorthand);
        to = convertShortHand(to, shorthand);

        //for each key in from add it to to
        for (var key in from) {


            //if the key is in the ignore list
            if (ignore.indexOf(key) > -1 || key == "namespace") {
                // console.log(`Ignoring ${key} from ${from.namespace} to ${to.namespace}`);
                continue;
            }
        
            // console.log(`Passing ${key} from ${from.namespace} to ${to.namespace}`);

            if (key == "class") {
                //join the classes
                if (key in to) {
                    to[key] += ` ${from[key]}`;
                } else {
                    to[key] = from[key];
                }
                continue;
            }

            to[key] = from[key];
        }


        // console.info(`Passed`, {
        //     from: from,
        //     to: to,
        //     ignore: ignore
        // });

        return to;
    } catch (error) {

        console.error("Error in jsonRender.pass", {
            error: error,
            from: from,
            to: to,
            ignore: ignore
        });
        
    }

    return from;
} module.exports.pass = pass;

var convertJSONtoDOM_Return = uae.RenderSafeUndefinedReturn;

// /**
//  * Creates an error element for the JSON Renderer
//  * Creates a DOM object.
//  * @param {*} error 
//  * @param {*} jsonObj 
//  * @returns 
//  */
// function createParseErrorElement(error, jsonObj) {
//     // console.error("Universe App Tools encountered an error.", {
//     //     error: error,
//     //     jsonObj: jsonObj
//     // });

//     return createError(error, jsonObj);

// }


//Uses the new uae module for this function
// var createError = uae.CreateError;
// module.exports.createError = createError;

// /**
//  * Creates a consistent error for the JSON Renderer (returns DOM object)
//  * @param {*} error The error to display
//  * @param  {...any} args 
//  * @returns a rendered error
//  */
// async function createErrorRender(error, ...args) {
//     return await convertJSONToDOM(createError(error, ...args));
// } module.exports.createErrorRender = createErrorRender;

/**
 * the decode text area doucment object to preserve memory.
 */
var txt_decode = null;

/**
 * Decodes HTML Character codes in javascript, without a libary.
 * Prserves the memory of one object.
 * @param {*} html 
 * @returns Decoded html
 */
function decodeHtml(html) {
    if (txt_decode == null) {
        txt_decode = document.createElement("textarea");
    }
    
    txt_decode.innerHTML = html;

    // console.log("result of decode", {
    //     html: html,
    //     txt_decode: txt_decode.value
    // })

    return txt_decode.value;
}

function test() {
    var test = {
        n: "section",
        i: [
            {
                n: "ua.fs.simple",
                i: {
                    n: "h1",
                    i: "Hello World",
                    c: "text-center"
                }
            }
        ]
    };

    //clear HTML
    document.querySelector("main").innerHTML = "";

    //convert JSON to DOM
    document.querySelector("main").appendChild(convertJSONToDOM(test));
} module.exports.test = test;

/**
 * Handles literal objects gracefully. #{propertyName} syntax, with support for multilevel properties.
 * @param {*} jsonString The string to parse for literals.
 * @param {*} context The context to use for the literals. If not provided, eval will be used (WARNING: This will enable code injection).
 * @returns The adjusted string with property values substituted.
 */
function handleLiterals(jsonString, context = undefined) {

    // console.log("json.Render Handle Literals", {
    //     jsonString: jsonString,
    //     context: context 
    // });

    // Helper function to get property value by path
    function getPropertyByPath(path, obj) {
        return path.split('.').reduce((prev, curr) => {
            return prev ? prev[curr] : undefined;
        }, obj);
    }

    // If the string is blank
    if (jsonString == "") {
        return jsonString;
    }

    // Check if there is at least one literal
    if (jsonString.indexOf("#{") == -1) {
        // No literals
        return jsonString;
    }

    // If context is undefined, return jsonString as we cannot safely evaluate without enabling code injection
    if (context === undefined) {
        // console.warn(`Warning: handl`, { jsonString });
        // console.warn(`Warning: Using JSON Literals without context can enable code injection.`, { jsonString });
        return jsonString; // Consider safely handling this case as per your application's security requirements.
    } else {
        try {
            const interpretedString = jsonString.replace(/#\{(.*?)\}/g, (_, propertyPath) => {
                const value = getPropertyByPath(propertyPath.trim(), context);
                return value !== undefined ? value : `#{${propertyPath.trim()}}`;
            });
            return interpretedString;
        } catch (error) {
            console.error("Error processing literals", error);
            return jsonString;
        }
    }
} module.exports.handleLiterals = handleLiterals;


// /**
//  * Handles literal objects gracefully. ${} only
//  * @param {*} str The string to parse for literals.
//  * @param {*} context The context to use for the literals, if not provided, eval will be used. WARNING: This will enable code injection.
//  * @returns The adjusted string.
//  */
// function handleLiterals(jsonString, _ctx = undefined) {

//     //if the string is blank
//     if (jsonString == "") {
//         return jsonString;
//     }

//     //is there at least one literal?
//     if (jsonString.indexOf("${") == -1) {
//         //no literals
//         return jsonString;
//     }

//     if (_ctx === undefined) {

//         //Need to add "safe" overide for config
//         //if the config is set to safe, then we will disable
//         //eval and return the string as is.

//         if (!window.UATisServer) {
//             console.warn(`Warning, using JSON Literals without context can enable code injection.`, {
//                 jsonString: jsonString
//             });
//         }

//         try {
//             return eval("`" + jsonString + "`");
//         } catch (error) {
//             return jsonString;
//         }


//     } else {
//         try {
//             const interpretedString = jsonString.replace(/\${(.*?)}/g, (_, propertyPath) => {
//                 const value = getProperty(propertyPath.trim(), _ctx);
//                 return value !== undefined ? value : `\${${propertyPath.trim()}}`;
//                 });
//                 return interpretedString;
//         } catch (error) {
//             return jsonString;
//         }

//     }

//   } module.exports.handleLiterals = handleLiterals;

  /**
   * Recursively handles literals on objects
   * @param {*} obj The object to parse
   * @param {*} context The context to use for the literals, if not provided, eval will be used. WARNING: This will enable code injection.
   * @returns The updated object adapted with literals.
   */
  function RecursivelyHandleLiterals(obj, c) {

    // console.log("RecursivelyHandleLiterals", {
    //     obj: obj,
    //     typeofObj: typeof obj,
    //     context: context
    // });

    if (typeof obj === "string") {
        // var rtn = handleLiterals(obj, context);
        // console.log("Return", {
        //     rtn: rtn
        // });
        return handleLiterals(obj, c);
    } else if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            obj[i] = RecursivelyHandleLiterals(obj[i], c);
        }
        return obj;
    } else if (typeof obj === "object") {
        for (var key in obj) {
            obj[key] = RecursivelyHandleLiterals(obj[key], c);
        }
        return obj;
    } else {
        return obj;
    }

  } 
  
/**
   * Recursively handles literals on objects
   * @param {*} obj The object to parse
   * @param {*} context The context to use for the literals, if not provided, eval will be used. WARNING: This will enable code injection.
   * @returns The updated object adapted with literals.
   */
  function recursivelyHandleLiterals(obj, c) {
    return RecursivelyHandleLiterals(JSON.parse(JSON.stringify(obj)), c);
  } module.exports.recursivelyHandleLiterals = recursivelyHandleLiterals;


  /**
   * Search for UA Element object
   * @param {*} namespace 
   * @returns Returns the UA Element object from the namespace.
   */
  function search(namespace) {
    return registry.search(namespace);
  } module.exports.search = search;

/**
 * Generates a JSON Wrapper for the UA Tools.
 * This can be used for server side rendering, when UA Tools can not be rendered server side.
 * It's an alternative to erroring out.
 * @param {*} options The options to pass through to the UA Tools.
 * @returns The rendered JSON Wrapper.
 */
async function generateJsonWrapper(options) {
     //I'm not comapatible with server side rendering (yet)

     var onlyClientSideRender = {
        "n": "json",
        "type": "ua/interface",
        // "render": "no-server",
        "i": JSON.stringify(options, null, 0) + ""
      };
  
      // console.info("Only client side render", onlyClientSideRender);
      return await convertJSONToDOM(onlyClientSideRender);
} module.exports.generateJsonWrapper = generateJsonWrapper;

