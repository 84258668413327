// var $ = require("jquery"); //no more JQUERY!!! CELEBRATE!
var f = require("../scripts/f.js");
var langError = require("./lang_error.js");
var config = require("../config.js");
var log = require("../scripts/log.js");
// var erroricon = "bi-exclamation-triangle-fill"; //What is this???
// var uae = require("../elements/templates/ua.element.js"); //UAE is dead!
var observer = require("./observer.js");
// var path = require("../../../uam/_path.js"); //not the standard path module


var jsonRender = require("./jsonRender.js");

var generateRandomID = require("../../../uam/functions/generateRandomID.js");
module.exports.generateRandomID = generateRandomID.function;

/**
 * Register Events
 */
var events = require("../../../uam/events.js");
events.init(true);

events.register([{
  namespace: "interface.afterrender",
  description: "After the interface has been rendered."
}, {
  namespace: "interface.oninteraction",
  description: "When the user interacts with the interface."
}, {
  namespace: "interface.prepare",
  description: "Before the interface is rendered these events will be called."
}]);

// function on(event, callback) {
//   return events.on(event, callback);
// } 

// module.exports.on = events.on;
// module.exports.onEvent = events.on;

// var uaRenderedAttribute = "ua-rendered";
// var uaErrorAttribute = "ua-error";
// var uaAnim = "ua-anim";

// //Default observable class, you should only do one.
// var uaDefaultObservableSelector = '[data-ua-observe]';

var uaAtrributeNames = {
  observe: "ua-observe",
  rendered: "ua-rendered",
  error: "ua-error"
};
// }; module.exports = uaAtrributeNames;

//the settings to run the prepare object
/**
 * This event is used to tell a user control to prepare for data submission.
 * @type {Event}
 */
var eventPrepare = new Event("prepare");

/**
 * Initilizes the interface.
 * @param  {*} registry [The array of objects that can be processed]
 * @return {*}          [description]
 */
 async function init (registry) {
  InterfaceRegistry = registry;
  jsonRender.init(registry);

  await RenderInterface();
} module.exports.init = init;

exports.RenderInterface = RenderInterface;

var isPrepared = false;

/**
 * To be tun before the interface is rendered.
 */
async function PrepareInterface() {

  if (isPrepared) {
    return true;
  }

  // console.log("Preparing Interface...");

  //call all interface.prepare events
  try {
    await events.run("interface.prepare");
  } catch (error) {
    console.error("Error running interface.prepare events.", error);
  }

  isPrepared = true;
  return true;

}

/**
 * The registry that is currently being processed.
 * @type {Boolean}
 */
var InterfaceRegistry = [];

function RenderObjectByNamespace(namespace, object) {
  object.namespace = namespace;
  return RenderObject(object);
}
exports.RenderObjectByNamespace = RenderObjectByNamespace;


// function GetRandomName() {
//   return "ua-" + Math.random().toString(36);
// } module.exports.

/**
 * Generates a UA Error message.
 * @param {*} fault The fualt of the error.
 * @param {*} message The message to display
 * @param {*} object The object to reveal to the developer.
 * @returns A DOM object.
 */
function uaGenerateErrorDiv(fault, message, object) {
  var div = document.createElement("ua.error");
  div.setAttribute("fault", fault);
  div.setAttribute(uaAtrributeNames.error, true);
  div.innerText = message;

  var commentBlock = document.createComment(`
${JSON.stringify(object, null, 2)}
  `);

  div.appendChild(commentBlock);
  return div;
}


/**
 * Renders one object. 
 * Must have a namespace.
 * Returns a DOM Object.
 * OnFault: Returns ua.error element, reports issue to log.
 * @param {*} object 
 */
async function RenderObject(object) {

  var isdom = f.isDomElement(object);
  // var foundNs = false;
  // var namespace = "";


  // log.info("Preparing to render...", {
  //   object: object,
  //   // namespace: namespace,
  //   isdom: isdom
  // })

  //if your a dom object convert it to a json object
  if (isdom) {
    var r = {};

    //convert the object to the new standard format
    // We've choosen JSON objects to make creating custom controls easier.
    // It's also far more fluid, data conscious, and easier to work with.
    r = jsonRender.convertDOM(object);

    // log.info("Converted DOM to JSON", r);

    //clear the dom Object's HTML - becuase we just rednered it according to the registry
    object.innerHTML = "";
    
    //append the new object to the dom object
    return await jsonRender.render(r);

  } else {

    //Renders and converts json
    return await jsonRender.render(object);

  }

  //if I'm a json object, create a holster
 
}

/**
 * Renders more than one object.
 * @param {*} objects 
 * @param {*} forceHolster Forces the objects to be wrapped in a div.
 */
async function RenderObjects(objects, forceHolster = false) {
  // console.log("Rendering Objects", objects);

  if ((typeof objects == "object") || (typeof objects == "array")) {
    //if I'm a json object, create a holster
    // console.log("Rendering Objects", objects);
    // var isdom = NodeList.prototype.isPrototypeOf(objects);
    // log.info("Objects set are node list", isdom);

     var isdom = f.isDomElement(objects);

     if (Array.isArray(objects)) {
        isdom = f.isDomElement(objects[0]);
     }

    //  log.info("Objects set are node list", isdom);
    
    if (isdom == false) {
      try {
        objects.sort((a, b) => a.order - b.order);
      } catch (error) {
        // log.info("Objects could not be sorted.", {
        //   error: error,
        //   objects: objects,
        //   type: typeof objects
        // });
      }
    }
    
    var parent = false;

    if ((isdom == false) || (forceHolster)) {
      parent = document.createElement("div");
      
      if (forceHolster) {
        parent.setAttribute("forceholster", true);
      } else {
        parent.setAttribute("fromjson", true);
      }

    }


    if (!("forEach" in objects)) {
      objects = [objects];
    }

    for (var i = 0; i < objects.length; i++) {
      var object = objects[i];

      try {
        
        var $child = await RenderObject(object);
        // console.log("Rendered object", {
        //   in: object,
        //   out: $child
        // });
        
        // console.log("Render results", $child);
        
        if (($child == false) || ($child === null)) {
          //an error occurred or the object has no chnages
          console.warn(`The namespace: ${object.namespace} returned false or null.`);
        } else if ($child === undefined) {
          //the return from the function never happened
          console.warn(`The namespace: ${object.namespace} did not return a value or returned undefined.
          A rendered object should return either false or null - unless there was a problem rendering the object.
          Object will be marked as rendered, to prevent reproducing this error.`)

        } else {
          if (isdom) {
            // console.log("I'm a dom object, so I'm replacing myself with the rendered object.");
            object.innerHTML = "";
            object.appendChild($child);
          } else {
            // console.log("I'm not a dom object, so I'm appending the rendered object to myself.");
            parent.appendChild($child);
          }
        }
      } catch (error) {
          //some error happened
          log.info("Couldn't render object...", {
            error: error,
            object: object
          })
      }

      setRendered(object);

    }

    return {
      success: true,
      isdom: isdom,
      holster: parent
    };

  } else {
    log.info("Provided object type could not be rendered.", objects);

    return {
      success: false,
      fault: "unsupportedType",
      error: "The provided object type could not be rendered.",
    };
  }

}

exports.RenderObjects = RenderObjects;

/**
 * Verifies that the UATools Renderer has been set up for server side rendering.
 * @returns The UATools.server object for easy handling.
 * @property {function} cache.get - Gets a cache object from it's easy name.
 */
function server() {

  if ("exit" in process) {
    return true;
  }
  
  return false;

} module.exports.server = server;

/**
 * Reports that the element has been rendered.
 * Use with isRendered function.
 * @param  {*} element [description]
 * @return {*}         [description]
 */
function setRendered(element) {
  if (window.UATisServer) {
    //If I'm server, no element is set to rendered.
    return;
  }
  element.setAttribute("uarendered", true);
}



/**
 * Renders the internal insides of an JSON HTML object.
 * Returns a dom object.
 * OnFualt: Returns the unrendered raw inner object.
 * @param {*} inner The inner JSON or HTML object to render.
 */
async function RenderInner(inner) {

  log.info("Request to render inner.", {
    inner: inner,
    type: typeof inner
  });

  var rtn = document.createElement("div");

  if (f.isDomElement(inner)) {
    //I'm a dom so let's start iterating through all the children
    //but recreate a object so that we can return it
    // rtn = document.createElement("div");

    //iterate through all the children
    inner.childNodes.forEach((child, i) => {
     
      //if the object has a namespace
      if (child.hasAttribute("namespace")) {
        rtn.appendChild(RenderObject(child));
      } else {
        //put it in the new object
        rtn.appendChild(child);
      }

    });
  
  } else if (typeof inner == "object") {

    // console.log("Inner is an object", inner);

    rtn.innerText = JSON.stringify(inner);


  } else if (typeof inner == "string") {
  
    //I'm a string, so let's see if I'm a JSON object
    try {
      var json = JSON.parse(inner);
      rtn = await RenderObjects(json);
    } catch (error) {
      //I'm not a JSON object, so I'm just a string, probably a html string (from options pass through)
      var div = document.createElement("div");
      div.innerHTML = inner;
    }


  }

  // log.info("Rendered Inner", rtn);
  return rtn;

} exports.RenderInner = RenderInner;


/**
 * Any element containing a dot in the tag name will be considered a custom UA element.
 * To avoid conflicts with other elements, you can use the ua-do-not-render attribute.
 * @returns An array of elements that contain a dot in the tag name.
 */
function getDOMUAObjects() {

  // console.log("Starting getting ua objects...")
  var allElements = document.querySelectorAll('*');
  var elementsWithDot = [];

  for (var i = 0; i < allElements.length; i++) {
    var element = allElements[i];
    if (element.tagName.includes('.')) {
      //a element with a protective ua-do-not-render will be ignored
      if (!(element.hasAttribute("ua-do-not-render"))) {
        elementsWithDot.push(element);
      }
    }
  }

  return elementsWithDot;
} 


/**
 * Renders all <ua> tags
 * Outdated <element> and <control> tags
 * Renders all <json type="text/json;uapart=true"> tags
 * @constructor
 */
async function RenderInterface() {

  PrepareInterface();

  // RenderObjectsDom()

    var elements = document.querySelectorAll('element:not([uarendered="true"])');
    var controls = document.querySelectorAll('control:not([uarendered="true"])');


    //if I have controls or elements display a depreciated warning
    if ((elements.length > 0) || (controls.length > 0)) {
      log.warn("The use of <element> and <control> tags is depreciated. Please use the new tag format <name.space>.", objects);
    }

    await RenderObjects(getDOMUAObjects());
    await RenderObjects(elements);
    await RenderObjects(controls);

  // RenderObjects(document.querySelectorAll('ua:not([uarendered="true"])'));

  // // log.info("Rendering Elements", document.querySelectorAll("element"));
  // RenderObjects(document.querySelectorAll('element:not([uarendered="true"])'));

  // // log.info("Rendering Controls", document.querySelectorAll("control"));
  // RenderObjects(document.querySelectorAll('control:not([uarendered="true"])'));

  // // log.info("Rendering Json", document.querySelectorAll('json:not([uarendered="true"])'));
  $json = document.querySelectorAll("json");
  $json.forEach(async ($j, i) => {
    //find a JSON tag that supports uapart's
    if (($j.getAttribute("type") == "ua/interface") && (!($j.hasAttribute("uarendered")))) {

      // console.log("Rendering JSON", $j);

      var json = "";

      try {

        //check if the Json has a src attribute
        if ($j.hasAttribute("src")) {
          //if it does, load the json from the src
          var src = $j.getAttribute("src");
          //using fetch, await/aysnc to get the json
          
          if (window.UATisServer) {
            var fs = require("fs");
            var path = require("path");

            try {

              var filePath = "";
              //for each staticDirs find the file
              for (var i = 0; i < window.UATServerCard.staticDirs.length; i++) {

                var staticDir = window.UATServerCard.staticDirs[i];
                var jsonPath = path.join(staticDir, src);

                // console.log("Checking for JSON", jsonPath);
                if (fs.existsSync(jsonPath)) {

                  // console.log("Found JSON", jsonPath);
                  filePath = jsonPath;
                  break;

                }

              }

              if (filePath == "") {
                throw "File not found.";
              }

              json = fs.readFileSync(filePath);
              json = JSON.parse(json);

            } catch (error) {
              console.log("UAT Server Side: JSON File could not be rendered or found.", {
                src: src,
                error: error
              })
              json = {
                n: "ua.e.alert",
                alertclass: "alert-danger",
                title: "UAT Server Side Rendering Error",
                i: [
                  {
                    n: "p",
                    i: `${src} could not be rendered or found.`
                  },
                  {
                    n: "p",
                    i: `Error: ${error}`
                  }
                ]
              }
            }
            // json = fs.readFileSync(path.join(window.UATServerCard.staticDir, src));
          } else {

            var json = await fetch(src,  {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'UA-Version': config.version
              }
            });
            json = await json.json();

          }
        } else {

          //get all properties like an exception of $j
         //newError = JSON.parse(JSON.stringify(newError, Object.getOwnPropertyNames(newError)));
          // var new$j = JSON.parse(JSON.stringify($j, Object.getOwnPropertyNames($j)));
        
          // console.info("Checking $J", {
          //   text: $j.innerText + "",
          //   html: $j.innerHTML
          // });

          // var jsonPrse = JSON.parse($j.$j.innerHTML);
          // console.info("Inline JSON Parse started", $j);

          //if $j.innerText is not empty and not undefined
          if (($j.innerText != "") && ($j.innerText != undefined)) {
            //get the json from the inner text
            json = JSON.parse($j.innerText.trim());
          } else {
            //get the json from the inner html
            json = JSON.parse($j.innerHTML.trim());
          }

          // console.info("JSON", {
          //   type: typeof json,
          //   json: json
          // });

        }
       
        var parent = null;
        // Moved this to the jsonRender.js file
        // if ("document" in $doc) {
        //   log.info("Rendering Json Document", $doc);
        //   parent = RenderObjects($doc.document);
        // } else {
        //   log.warn("The JSON doesn't have a document property (array of elements).", $doc);
        // }

        $result = await jsonRender.convertJSONToDOM(json);

        // console.log("Result from JSON Render", {
        //   json: json,
        //   result: $result
        // });
        // console.info("Result from JSON Render", {
        //   json: json,
        //   result: $result
        // });

        // console.log("Result from JSON Render", {
        //   json: json,
        //   result: $result
        // })
        
       
        //replaces JSON tag with rendered object
        // log.info("Attempting document addition...", $j, $result);

        if (window.UATisServer) {
          //I'm a server side rendering of a JSON Object, so place a noscript tag

          // var noscript = document.createElement("noscript");
          // noscript.setAttribute("ua", true);
          // noscript.appendChild($result);
          $j.classList.add("uaServerSide");
          $j.appendChild($result);

          // console.log("I think I'm server side!");

        } else {
          $j.parentNode.replaceChild($result, $j);
          // console.log("I think I'm cleint side!");
        }
        
        //$json[i] = parent;

        setRendered($j);

      } catch (err) {

        if (json == "") {
        } else {
          console.error("The JSON object could not be rendered.", {
            json: json,
            parent: parent,
            err: err
          });
          throw err;
        }
      }

    } else if (($j.getAttribute("type") == "console/log") && (!($j.hasAttribute("processed")))) {
      // console.log("I'm a console log", $j);
      $j.setAttribute("processed", true);
      var json = JSON.parse($j.innerText);
      console.info("This document includes a JSON object log entry.", JSON.parse($j.innerText));
      // $j.parentNode.removeChild($j);
    }

    //This is an Async function so we need to call AfterRender
    //inside the function.

    AfterRender();

  });

  // //Add observers
  // console.log("Rendering final components...")
  AfterRender();
  
}

function RenderTooltips() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

// /**
//  * Draws a universe app response to the screen.
//  * @param {*} response 
//  */
// window.DrawResponse = function (response) {
//   PerformResponse(response);
// };

exports.PerformResponse = PerformResponse;
async function PerformResponse(response) {

  var $main = document.querySelectorAll(config.main);

  if ($main === null) {
    error = langError.unsetresponse;
    ShowErrorMessage(error.title, error.body, "The document contains no main element.", true);
    return;
  } else {
    if ($main.length !== 1) {
      error = langError.unsetresponse;
      ShowErrorMessage(error.title, error.body, "The document contains more than one main element.", true);
      return;
    }
  }

  // Original behavior was to clear the main for each request.
  // Now handles a request through "append" attribute
  //
  // $main[0].innerHTML = "";

  var uaResponse = false;
  // if response is a object not a string render it
  if (typeof response == "object") {

    var myResponse = false;
    //is it a node
    if (f.isDomElement(response)) {
      myResponse = response;
    } else {
      myResponse = await jsonRender.render(response);
    }
    
    //wrap it all in a <response> tag
    var responseTag = document.createElement("response");
    responseTag.appendChild(myResponse);
    uaResponse = responseTag;

    //else if it's a string
  } else {
    uaResponse = f.createResponse(response);
  } 

  // var uaResponse = f.createResponse(response);
  log.info(uaResponse);

  var message = uaResponse.querySelector("message");
  if (message === null) {
    log.info("No message.");
  } else {

    ShowErrorMessage(message.getAttribute("title"), message.getAttribute("body"), message.getAttribute("footer"), message.hasAttribute("html"));

    // ShowResponse($main, page);
  }


  //ads support for rendering full pages
  var page = uaResponse.querySelector("page");
  if (page === null) {
    log.info("No page.");
  } else {
    
    //Am I appending or replacing?
    if (!(page.hasAttribute("append"))) {
      $main[0].innerHTML = "";
    } 
    
    ShowResponse($main, page);
  }

  //ads support for adding scripts
  var script = uaResponse.querySelector("script");
  if (script === null) {
    //log.info("No page.");
  } else {
    ShowResponse($main, script);
  }

  //ads support for history api - pages are real pages
  var pushhistory = uaResponse.querySelector("pushhistory");
  if (pushhistory === null) {
    // log.info("No history.");
  } else {
    PushHistory(pushhistory);
  }

  //ads support for next api - redirecting to another location - ie next step
  var next = uaResponse.querySelector("next");
  if (next === null) {
    //log.info("No history.");
  } else {
    GoNext(next);
  }

  //ads support for rendering partial parts of a page
  //<parts> and <section> {parts:} {section:}
  var parts = uaResponse.querySelector("part");
  if (parts === null) {
    parts = uaResponse.querySelectorAll("section");
  }

  if (parts === null) {
    //log.info("No selector.");
  } else {
    //allows for multiple parts to be processed and re-rendered by server
    parts.forEach((part, i) => {
      //log.info("Rendering part");
      ShowResponse(document.querySelectorAll(part.getAttribute("query")), part);
    });
  }

  //render the interface, custom controls and elements
  await RenderInterface();

    //Add observers
    // console.log("Rendering final components...")
    AfterRender();

};

function GoNext(next) {
  var uri = next.getAttribute("uri");
  var type = next.getAttribute("type");

  if (type == "redirect") {
    window.location = uri;
  }
}

function PushHistory(history) {
  // log.info("Adding to history...", history);
  var uri = history.getAttribute("uri");
  if (uri === null) {
    log.error("History URI can not be null.", history);
  }

  var title = history.getAttribute("title");
  if (uri === null) {
    log.error("History Title can not be null.", history);
  }

  var method = history.getAttribute("method");
  if (method === null) {
    log.error("History Title can not be null.", history);
  }

  var data = {};
  try {
    f.extend(data, data, JSON.parse(history.innerText));
  } catch (e) {

  } finally {

  }

  var uaaction = {
    "uri": uri,
    "method": method,
    "disableWhileLoading": true,
    "data": data,
    "uaversion": config.version
  };

  document.title = title;
  window.history.pushState(uaaction, title, uri);

}

exports.ValidateControl = ValidateControl;
//Check that objects are in a state of validity, and prepare data
function ValidateControl(control) {
  control.dispatchEvent(eventPrepare);
  //console.log("Working on...", control);

  jsondom = control.querySelector("json");

  if (jsondom === null) {
    log.warn("The control did not pass a proper JSON element. (null)", control.getAttribute("namespace"), control);
  }

  itext = jsondom.innerText;
  if (itext === undefined) {
    log.warn("The control did not pass a proper JSON element. (no-inner-text)", control.getAttribute("namespace"), control);
  }

  var json = false;
  try {
    json = JSON.parse(itext);
  } catch (e) {
    log.warn("The JSON object provided by the control failed validation.", control.getAttribute("namespace"), control);
    throw "The JSON object provided by the control failed validation.";
  } finally {

  }


  //console.log(json);

  if (json.validation.valid == false) {
    control.classList.add("is-invalid");
    control.classList.remove("is-valid");

    log.warn(json.options.name + " failed validation. " + json.validation.message, control);
    return false;

  } else {

    control.classList.remove("is-invalid");
    control.classList.add("is-valid");
    return true;

  }

}

//var lastResponse;
function ShowResponse($main, uaResponse) {
  //lastResponse = uaResponse;
  log.info("Render Response:", uaResponse);

  if ($main === null) {
    error = langError.unsetresponse;
    ShowErrorMessage(error.title, error.body, "The response asked to populate an element that does not exist.", true);
  } else {
    if ($main.length == 1) {
      $main[0].appendChild(uaResponse);
      $main[0].scrollIntoView(true);
    } else {

      error = langError.unsetresponse;
      ShowErrorMessage(error.title, error.body, "The response asked to populate more than one element.", true);
    }
  }

}

// function AppendResponse($main, uaResponse) {
//   log.info("Render Response (appending):", uaResponse);

//   if ($main === null) {
//     error = langError.unsetresponse;
//     ShowErrorMessage(error.title, error.body, "The response directive contained no element.", true);
//   } else {
//     if ($main.length == 1) {
//       $main[0].appendChild(uaResponse);
//       $main[0].scrollIntoView(true);
//     } else {

//       error = langError.unsetresponse;
//       ShowErrorMessage(error.title, error.body, "The response directive contains more than one element.", true);
//     }
//   }

// }

exports.ShowErrorMessage = ShowErrorMessage;

// window.ShowErrorMessage = ShowErrorMessage;

/**
 * Draws an Error Message on top of the document, and scrolls the document to the top.
 * @param {*} header The title, header, or name of the error message.
 * @param {*} body The body to display. Plain text or HTML (use is HTML to activate html rendering).
 * @param {*} footer The Error message details to help developers debug (namespace, id, etc).
 * @param {*} isHtml Does the error message body contain HTML.
 */
function ShowErrorMessage(header, body, footer, isHtml) {

    var msg = document.createElement("message");
    msg.className = "fullscreen-message";

    if (isHtml) {
      var h1 = document.createElement("h1");
      h1.innerHTML = header;
      msg.appendChild(h1);

      var inside = document.createElement("div");
      inside.innerHTML = body;
      msg.appendChild(inside);

      if (footer.trim().length >0) {
        //Error footer
        var foot = document.createElement("p");
        foot.className = "error alert alert-dark";
        foot.innerHTML = footer;
        foot.style.display = "block";
        msg.appendChild(foot);
      }

    } else {

      var h1 = document.createElement("h1");
      h1.innerText = header;
      msg.appendChild(h1);

      var inside = document.createElement("div");
      inside.innerText = body;
      msg.appendChild(inside);

      if (footer.trim().length >0) {
        var foot = document.createElement("footer");
        foot.className = "error alert alert-dark";
        foot.innerText = footer;
        foot.style.display = "block";
        msg.appendChild(foot);
      }

    }

    var btn = document.createElement("button");
    btn.classList.add("btn");
    btn.classList.add("btn-primary");
    btn.innerHTML = "Close";
    btn.title = "Close";
    btn.addEventListener("click", CloseFullscreenErrorMessage);

    msg.appendChild(btn);

    document.body.appendChild(msg);
    document.body.style.overfolow = "hidden";

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

}

function CloseFullscreenErrorMessage() {
  document.body.removeChild(document.querySelector(".fullscreen-message"));
}


exports.UpdateControlsAfterValidation = UpdateControlsAfterValidation;
/**
 * Checks that form controls have validated from form - updates control's sgtyle
 * @constructor
 */
function UpdateControlsAfterValidation(form) {
  form.querySelectorAll("control").forEach(function (control) {
    UpdateControlAfterValidation(control);
  });
}

exports.UpdateControlAfterValidation = UpdateControlAfterValidation;
/**
 * Checks that the control has been validated - updates it's style.
 * @param       {*} control [description]
 * @constructor
 */
function UpdateControlAfterValidation(control) {
  //console.log("Am I invalid or valid, also input field.", control.querySelectorAll("input:invalid"), control.querySelectorAll("input:valid"), control.querySelectorAll("input"));
  if (control.querySelectorAll("input:invalid").length > 0) {
    control.classList.remove("is-valid");
    control.classList.add("is-invalid");
  } else {
    //console.log("Test:", control);
    control.classList.remove("is-invalid");
    if (control.querySelectorAll("input:valid").length > 0) {
      control.classList.add("is-valid");
    } else {
      control.classList.remove("is-valid");
    }
  }
}


// /**
//  * The Interface events object.
//  */
// var events = {
//   afterrender: [],
//   oninteraction: []
// };

// /**
//  * Adds a function to the event listener.
//  * AfterRender = Occurs anytime the Dom has been rendered by UATools.
//  * OnInteraction = Occurs when the user first interacted with the page.
//  * @param {*} event The event name.
//  * @param {*} callback The function to call.
//  */
// function on(event, callback) {
//   event = event.trim().toLowerCase();
//   if (event in events) {
//     events[event].push(callback);
//   } else {
//     throw `Could not add listener. The event type ${event} is not supported.`;
//   }
// } module.exports.on = on;


// //add the built in events
events.on("interface.afterrender", RenderTooltips);
events.on("interface.afterrender", () => {
  observer.observe(`[${uaAtrributeNames.observe}]`);
});

// /**
//  * Calls the functions assoicated with the event type specified, but keeps it attached to the orginal module to keep varaible access to that module.
//  * @param {*} event The name of the event to call
//  */
// function callEvent(event) {

//   event = event.trim().toLowerCase();

//   if (event in events) {
//     events[event].forEach((callback, i) => {
//       callback();
//     });
//   } else {
//     throw `Could not perform event. The event type ${event} is not supported.`;
//   }

// }

// function callEvent(event) {
//   events.run(event);
// } module.exports.callEvent = callEvent;

/**
* Aftert the DOM is rendered, anything that needs reattached can be added here.
* This WILL NOT be called during server side rendering, the DOM isn't visible to a client yet.
*/
function AfterRender() {

  // //iterate through all functions that need to be called after render
  // events.afterrender.forEach((callback, i) => {
  //   callback();
  // });

  //I'm running in server mode
  if (window.UATisServer) {
    return;
  }

  events.run("interface.afterrender");

  //remove the first node of any <json> tag if it is a text node
  // var jsons = document.querySelectorAll("json");
  // jsons.forEach(($json, i) => {
  //   //are theire any child nodes?
  //   if ($json.childNodes.length > 0) {
  //     //if there are, remove the default {} text node
  //     if ($json.childNodes[0].nodeType == Node.TEXT_NODE) {
  //       $json.removeChild($json.childNodes[0]);
  //     }
  //   }
  // });

  // RenderTooltips();.
  // observe(`[${uaAtrributeNames.observe}]`);

} module.exports.AfterRender = AfterRender;



/** New Objects Render function.
 * Updated to do the following:
 * Render all objects, that have a namespace
 */


try {
  /**
 * Fires the OnInteraction event when the user first interacted.
 */
function onInteraction() {

  //remove the listener
  document.body.removeEventListener("click", onInteraction);
  events.run("interface.oninteraction");
  
} document.body.addEventListener("click", onInteraction);


} catch (error) {
  console.info(`UAT is running in a server environment. The onInteraction event will not be fired. ${error}`);
}


/**
 * Resolves a path to the root of the web directory and returns a fully qualified URL. 
 * @param  {...any} path 
 */
function resolveURL(...path) {
  // path = [...path];

  // console.log("Resolving ", {
  //   path: [...path]
  // })

  // if (server()) {

  //   // console.log("Server card", window.UATServerCard);
  //   if (path[0].substr(0,1) == "~") {
  //     throw new Error("Using a tilda for path resolution is not supported. Use the @! instead.");
  //   } else if (path[0].substr(0,2) == "@!") {

  //     path[0] = path[0].replace("@!", GetCompleteURL_WithoutPAth());

  //     //if it does, replace it with the current directory
  //     // path[0] =  path[0].replace("~", path.join(window.location.protocol + "//" + window.location.host + uri));
  //   }


  // } else {

    //get first path and check if it has a @!
    if (path[0].substr(0,1) == "~") {
      throw new Error("Using a tilda for path resolution is not supported. Use the @! instead.");
    } else if (path[0].substr(0,2) == "@!") {

      path[0] = path[0].replace("@!", GetCompleteURL_WithoutPath());

      //if it does, replace it with the current directory
      // path[0] =  path[0].replace("~", path.join(window.location.protocol + "//" + window.location.host + uri));
    }

  // }


  // var rtn = path.join("/");
  // console.log(`Resolved path rtn.`, {
  //   path: [...path],
  //   rtn : rtn
  // });

  return path.join("/");

  // path.join(window.location.protocol + "//" + window.location.host + uri, ...path;

} module.exports.resolveURL = resolveURL;

/**
 * Gets the complete URL without the path. 
 * Example: https://www.example.com:8080
 * @returns The complete URL without the path. Example: https://www.example.com:8080
 */
function GetCompleteURL_WithoutPath() {

  // //is there a port
  // var port = "";
  // if (window.location.port != "") {
  //   port = ":" + window.location.port;
  // }

  // console.log("Getting Complete URL without path...", {
  //   server: server(),
  //   UATServerCard: window.UATServerCard,
  //   url:  window.UATServerCard.my.app.url
  // });

  if (server()) {
    return window.UATServerCard.my.app.url;
  } else {
    return window.location.protocol + "//" + window.location.host;
  }

}

async function serverSleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Gets a resource from a URI. Will resolve server paths with resolveURL.
 * Supports Server Side rendering and Clinet Side rendering.
 * Client: Uses the Fetch API, Check and resolve errors on specific use cases.
 * Server: Uses the FileManager to access files directly (preventing the FS module from being exposed to the client - which won't pack anyway).
 * @param {*} _uri The URI to get the resource from.
 * @param {*} type The type of resource to get. (html, json) Other types, not yet supported. 
 * @param {*} method The method to use to get the resource. (GET, POST, PUT, DELETE, etc)
 * @param {*} returnResult Should I return the response as the fetch.response{} object, or parse it as json or text (defualt).
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Response Response Object Documentation
 * @returns The contents of the file or the response object
 * @throws Error if the resource could not be found or there was an error.
 * @throws Common File System Errors if its the server.
 */
async function getObject(_uri, type, method = "GET", returnResult = false, _headers = {}) {

  // console.info(`Is server? ${server()}`);

  if (!(server())) {

    // //wait
    // serverSleep(1000);
    // process.exit(7);

    var headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'UA-Version': config.version
    };

    //add headers from _headers
    headers = {
      ...headers,
      ..._headers
    };


    var uri = resolveURL(_uri);
    
    // console.info(`Requesting ${_uri}, treating as ${type}...`, {
    //   method: method,
    //   returnResult: returnResult,
    //   headers: headers,
    //   type: type,
    //   uri: uri
    // });

    // //check is the source a relative path (starts with /) or has no protocol
    // if ((uri.startsWith("/")) || (!(uri.includes("://")))) {
    //   //if it is, grab the current host and protocol from the window and update the uri
    //   uri = window.location.protocol + "//" + window.location.host + uri;
    // }
    
    //select on type
    switch (type) {
      case "html":
        //get's html and requests the unrendered part where applicable
        headers['Content-Type'] = 'text/html';
        headers['Accept'] = 'text/html, text/ua+html';
  
        break;
      case "json":
        //get's json and requests the unrendered part where applicable
        headers['Content-Type'] = 'application/json';
        headers['Accept'] = 'application/json, application/ua+json';
        break;
      default:
        //Warning requests for resources outside of UA are at the descrition of the web server instance.
        //and if they exsist.
        // throw new Error("Universe App Tools doesn't support your requested type, yet.");
        break;
    }

    // var uri = _uri.replace("@!", window.location.protocol + "//" + window.location.host + uri);

    var result = await fetch(uri,  {
      method: method,
      headers: headers
    });

    if (!result.ok) {
      // Handle the failed request here, e.g., throw an error or log a message
      throw new Error(`Failed to get resource ${uri} with status ${result.status}: ${result.statusText}.`);
    }

    var $return = false;

    if (returnResult) {
      //allows the user to work with abnormal (not UA) resultss
      $return = result;
    }

    if (type == "json") {
      $return = await result.json();
    } else {
      //in the future support other types?
      $return = await result.text();
    }

    // console.info(`Result of ${_uri}, treating as ${type}...`, {
    //   returnResult: $return
    // });

    return $return;
    
  } else {

    // console.info(`Requesting ${_uri}, as server`);

    //I'm server side, The FS module will be unavailable to the clinet so use FM instead.
    //in the future the file manager will control cloud storage as well

    // var fm = require("../../../uam/filemanager.js");

  
    // console.log("Window UAT Server Card", {
    //   card: window.UATServerCard,
    //   staticDirs: window.UATServerCard.staticDirs
    // });

    if (type == "json") {
      return FindFile(_uri, true);
    } else {
      return FindFile(_uri);
    }

  }

} module.exports.getObject = getObject;

/**
 * Finds a file in the static directories provided by the server.
 * If the file isJSON but it can't be parsed, it will return as raw text.
 * @param {*} file The file to find.
 * @param {*} isJson Is the file a JSON file, if so, it will be parsed as JSON.
 * @returns The contents of the file or the JSON object as requested.
 */
function FindFile(file, isJson = false) {

  // console.log("Finding File", {
  //   file: file,
  //   isJson: isJson
  // });

  var fm = require("../../../uam/filemanager.js");

  if (!("UATServerCard" in window)) {
    throw new Error("This function is not accessible from the client.");
  }

  if (!("staticDirs" in window.UATServerCard)) {
    throw new Error("The static directories were not provided by the server.");
  }

  //for each staticDirs find the file
  for (var i = 0; i < window.UATServerCard.staticDirs.length; i++) {

    var staticDir = window.UATServerCard.staticDirs[i];
    var filePath = file;

    //if a file has a ~ join it
    if ("~" == filePath.substring(0,1)) {
      console.log(`File: ${file} can no longer be used. Replace with @!`);
      throw new Error("Using a tilda for path resolution is not supported. Use the @! instead.");
    } else if ("@!" == filePath.substring(0,2)) {
      //if it does, replace it with the current directory
      filePath = fm.join(staticDir, file.replace("@!", ""));
    }

    // console.log("Searching for file...", filePath);

    var rtn = false;

    // console.info("Checking for file...", {
    //   filePath: filePath,
    //   exsists: fm.exists(filePath),
    //   isJson: isJson
    // })

    try {
    
      if (fm.exists(filePath)) {
        if (isJson) {
          try {
            rtn = fm.loadJSON(filePath);
          } catch (error) {
            
          }

        }
        rtn = fm.load(filePath);
      } else {
        //search for the next one
        // throw new Error(`The file ${file} could not be found.`);
      }

    } catch (error) {
      
      throw new Error(`The file ${file} could not be loaded. Reason: ${error}`);
      
    }


    if (rtn) {
      // console.log(`Found file ${file} in ${staticDir}.`, rtn);
      return rtn;
    }

  }

  throw new Error(`The file ${file} could not be found.`);

}

