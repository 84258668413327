/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

var f = require("../scripts/f.js");
var uae = require("./templates/ua.element.js");
var log = require('../scripts/log.js');
var required = ["title"];
var nopass = []; //the items that don't get passed to the object.

var namespace = "ua.e.header";
exports.namespace = namespace;

exports.render = function (ele) {
  var options = uae.GetOptions(ele, required);

  var divupper = document.createElement("div");

  var div = document.createElement("div");
  div.classList.add("row");
  div.classList.add("p-4");

  var h1 = false;
  if ("level" in options) {
    h1 = document.createElement("h" + level);
  } else {
    h1 = document.createElement("h1");
  }

  h1.innerText = options.title;
  
  if ("links" in options) {
    //support ua-action links
  } else {
    div.appendChild(h1);
  }


  //if the value subtext exists, add it to the header.
  if ("subtext" in options) {
    var p = document.createElement("p");
    p.innerText = options.subtext;
    p.classList.add("p-2");
    p.classList.add("text-muted");
    div.appendChild(p);
  }

  divupper.appendChild(div);


  //log.info("The image is passing...", img);
  return divupper;

};

/**
 * Report if the alert is rendered. (uae)
 * @param  {[type]} element [description]
 * @return {[type]}         [description]
 */
exports.isRendered = function (element) {
  return uae.isRendered(element);
}

exports.help = {
  title: "Header",
  description: "A header element.",
  namespace: namespace,
  options: {
    title: {
      description: "The title of the header.",
      type: "string",
      required: true,
      default: false
    }, 
    subtext: {
      description: "The subtext of the header.",
      type: "string",
      required: false,
      default: false
    },
    ...uae.options,
    type: "element",
    example: {
      html: `<element namesapce=${namespace} title="Welcome home!" subtext="You are home..." />`,
      json: {
        namesapce: namespace,
        title: "Welcome home!",
        subtext: "You are home..."
      }
    }
  }

}