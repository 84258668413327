/**
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var f = require("../scripts/f.js");
var log = require("../scripts/log.js");
var jsonRender = require("../interface/jsonRender.js");
var ui = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var footnotes = require("./ua.footnotes.js");

var required = ["inner"];
exports.required = required;

var namespace = "ua.footnote";
exports.namespace = namespace;

exports.define = {
  supportServer: false,
  namespace: namespace,
  fields: [],
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @ua:uses ua.a.incenter
 * @param {*} ele The element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = function (options) {
  // var options = uae.GetOptions(ele, required);
  // if (!(options.uarendered === true)) {

  // throw new Error("Footnotes are not yet supported.");

    var footnote = document.createElement("sub");
    footnote.classList.add("footnote");
    // footnote.classList.add("");
    // footnote.classList.add("bg-muted");

    var myid = ui.generateRandomID(12);

    var footnoteId = footnotes.FindFootnoteId(options.inner);

    //check if the footnote is already in the list
    if (footnoteId === -1) {
      footnoteId = footnotes.add(options.inner);
    }

    footnote.innerHTML = `<a href="#footnote-${footnoteId}" class="ua-footnote">[${footnoteId}]</a>`
    return jsonRender.render({
      "n": "a",
      "id": myid,
      "ua-return": true,
      "c": "ua-a-incenter", //activated with ua.a.incenter
      "href": `#footnote-${footnoteId}`,
      "i": `[${footnoteId}]`
    }, {
      PassthroughOptions: options
    });

  // }

};

// /**
//  * Removes the element from the footnote list.
//  * @param {*} ele 
//  */
// function unloadElement(ele) {
//   footnotes.remove(options.inner);
// } exports.unloadElement = unloadElement;