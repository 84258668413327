/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "pages.page";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var f = require("../scripts/f.js");

// var uai = require("../interface/interface.js");
// const { l } = require("../../../uam/sanitize.js");

var describeAccountingDate = require("../../../uam/functions/describeAccountingDate.js").function;

var required = ["title", "description", "image", "inner", "url", "on.published"];
exports.required = required;

exports.define = {
  namespace: namespace,
  fields: {
    "title": {
      type: "string",
      description: "The title of the page."
    }, "description": {
        type: "string",
        description: "The description of the page."
    }, "image": {
        type: "uri",
        description: "The image of the page (used in some page types), visible to social media."
    }, "inner": {
        type: "inner",
        description: "The inner content of the page."
    }, "url": {
        type: "uri",
        description: "The url of the page (this is autogenerated) - it will be overridden."
    }, "on": {
        type: "object",
        description: "The dates of the page."
    }, "on.published": {
        type: "date",
        description: "The date the page was published."
    }, "on.modified": {
        type: "date",
        description: "The date the page was last modified."
    }, "on.created": {
        type: "date",
        description: "The date the page was created."
    }, "head": {
        type: "inner",
        description: "Specific meta tags and style settings for the page. Currently only autogenerated by the pages.meta object."
    }, "useBackgroundImage": {
        default: true,
        type: "boolean",
        description: "Should the page use the default background image?"
    }, "useNavigation": {
        default: true,
        type: "boolean",
        description: "Should the page use the default navigation?"
    }, "useDocumentEnd": {
        default: true,
        type: ["boolean", "string"],
        description: "Should the page use the default footer? It's also possible to pass the name of the part to use in it's place."
    }
  },
  required: required
};

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

 //convert options.data from 20231010 to October 10, 2023
 options.on.describePublished = describeAccountingDate(options.on.published);

 if (!("url" in options)) {
    console.error("Page url can not be found.", {
        options: options,
        test: ("url" in options),
        stack: f.ParseStackTrace(new Error().stack)
    });
    options.url = window.location.href;
 }

 var meta = {
    "n": "pages.meta",
    "isArticle": true,
    "title": options.title,
    "description": options.description,
    "image": options.image,
    "url": options.url,
    // "author": options.author.name,
    "published": options.on.published,
    // "keywords": options.keywords,
    "social": {
        "author": {},
        "site": {},
        "app": {}
    }
};

if ("author" in options) {
    meta.author = options.author.name;

    if ("social" in options.author) {
        if ("twitter" in options.author.social) {
            meta.social.author.twitter = options.author.social.twitter;
        }

        if ("facebook" in options.author.social) {
            meta.social.author.facebook = options.author.social.facebook;
        }

        if ("instagram" in options.author.social) {
            meta.social.author.instagram = options.author.social.instagram;
        }

        if ("linkedin" in options.author.social) {
            meta.social.author.linkedin = options.author.social.linkedin;
        }

        if ("youtube" in options.author.social) {
            meta.social.author.youtube = options.author.social.youtube;
        }

        if ("tiktok" in options.author.social) {
            meta.social.author.tiktok = options.author.social.tiktok;
        }

        if ("twitch" in options.author.social) {
            meta.social.author.twitch = options.author.social.twitch;
        }

        if ("snapchat" in options.author.social) {
            meta.social.author.snapchat = options.author.social.snapchat;
        }

        if ("reddit" in options.author.social) {
            meta.social.author.reddit = options.author.social.reddit;
        }

        if ("pinterest" in options.author.social) {
            meta.social.author.pinterest = options.author.social.pinterest;
        }

        if ("github" in options.author.social) {
            meta.social.author.github = options.author.social.github;
        }

        if ("tumblr" in options.author.social) {
            meta.social.author.tumblr = options.author.social.tumblr;
        }

        if ("soundcloud" in options.author.social) {
            meta.social.author.soundcloud = options.author.social.soundcloud;
        }

        if ("spotify" in options.author.social) {
            meta.social.author.spotify = options.author.social.spotify;
        }

        if ("mixcloud" in options.author.social) {
            meta.social.author.mixcloud = options.author.social.mixcloud;
        }

        if ("bandcamp" in options.author.social) {
            meta.social.author.bandcamp = options.author.social.bandcamp;
        }

        if ("cashapp" in options.author.social) {
            meta.social.author.cashapp = options.author.social.cashapp;
        }

    }
}


var newPage = [
    meta, {
        n: "page",
        c: "p-0 m-0"
    }];


    newPage[1] = await jsonRender.pass(options, newPage[1], ["description", "image", "on", "title"]);

    // console.info("Page results", newPage);
 
 //Let's build meta tags
  return await jsonRender.render(newPage);
  
};
