/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.fs.image";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uae = require("./templates/ua.element.js");
// var uai = require("../interface/interface.js");
var events = require("../../../uam/events.js");

var required = ["inner"];
exports.required = required;



exports.define = {
  namespace: namespace,
  fields: [{
    "src": {
      type: "uri", 
      description: "The source of the image."
    }
  }],
  required: required
} 

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

 
    var article = {
      "n": "article",
      //class for the article contianer not the image-fixe ua-fs-background-image
      "c": "container-fluid vh-100 d-flex ua-fs-background-image-holster",
      "i":  [{
        n: "img",
        c: "ua-fs-background-image",
        src: `${options.src}`
      }]
    };

    if ("fixed" in options) {
      if (!(options.fixed)) {
        //it's false
      } else {
        article.i[0].c += " image-fixed";
        // article.i[0].$zi = false;
      }
    }

    if ("class" in options) {
      article.i[0].c += " " + options.class;
      delete options.class;
    }

    if ("inner" in options) {
      article.i.push({
        n: "ua.fs.simple",
        i: options.inner
      });
    }
   

    return await jsonRender.render(article, {
      PassthroughOptions: options
    });

};


//Hide and show ua-fs-background-image;
events.on("interface.afterrender", async function() {

  // Select all videos with the specified class
  const images = document.querySelectorAll('.ua-fs-background-image-holster');


  // Create an Intersection Observer for each "fixed image" to hide and show
  images.forEach(video => {
      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.target.hasAttribute("fixed")) {
              if (entry.target.getAttribute("fixed") == "true") {
                if (entry.isIntersecting) {
                  // console.log("Image is visible", entry.target);
                  entry.target.querySelector(".ua-fs-background-image").classList.remove("d-none");
                  // entry.target.classList.remove('d-none');
                } else {
                  // console.log("Image is not visible", entry.target);
                  entry.target.querySelector(".ua-fs-background-image").classList.add("d-none");
                  // entry.target.classList.add('d-none');
                }
              }
            }
          });
      }, {
          threshold: 0.01
      }); // Adjust threshold as needed

      observer.observe(video);
    });

});


/** 
<article class="container-fluid vh-100 d-flex">
<div class="row m-auto">
    <div class="col" data-ua-observe>
        <h1 class="text-center">Welcome to The Universe</h1>
    </div>
</div>
</article> */
