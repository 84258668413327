/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.hcard.simple";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");

var required = ["inner", "image"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "A Simple Highlighted Card",
  description: "Used to highlight a product, service, or idea.",
  fields: {
    "inner": {
      type: "array of list.item", 
      description: "The inner items of the listgroup."
    },
    "image": {
      type: "uri",
      description: "The path to the image to display.",
      required: true
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var style = ``;

  if ("image" in options) {
    style += `background: url(${options.image}) no-repeat top center; background-size: cover;`;
  }
 
  // var options = json;
  var card = {
    n: "div",
    s: style,
    c: "col-12 d-flex align-items-center",
    i: {
      n: "div",
      s: "width: 80%; padding-top:5rem; padding-bottom: 5rem;",
      c: "d-block m-auto",
      i: options.inner
    }
  };

  return await jsonRender.convertJSONToDOM(card, {
    PassthroughOptions: options
  });

}

/** 
 * 
 * 
 * 
 {
            "style": "outline: rgba(255,255,255,.2) .5px inset; background: url(../vis/database.jpg) no-repeat top center; background-size: cover;",
            "namespace": "div",
            "class": "col-12 d-flex align-items-center rounded",
            "inner": {
              "style": "width: 80%; padding-top:5rem; padding-bottom: 5rem;",
              "namespace": "div",
              "class": "d-block m-auto",
              "inner": [
                {
                  "namespace": "h2",
                  "class": "fs-3 text-shadow",
                  "inner": "Namespace"
                },
                {
                  "namespace": "p",
                  "class": "text-shadow",
                  "inner": "Be empowered by an infinitely scalable database that transforms the backbone of your application via an elevating API."
                }
              ]
            }
          }
*/
