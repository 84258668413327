document.addEventListener("DOMContentLoaded", function () {
    const body = document.body;
    // let assetsLoaded = false;


    var myinterval = null;
    function showLoading() {
        body.classList.add("loading");
        body.classList.remove("loaded");
        myinterval = setTimeout(() => {
            body.classList.add("loading-more-than4");
        }, 4000);
    }

    function hideLoading() {
        // if (assetsLoaded) {
            setTimeout(() => {
                setTimeout(() => {
                    body.classList.add("loaded");
                }, 1000);
                body.classList.remove("loading");
                body.classList.remove("loading-more-than4");
            }, 1000);

            //remove the timeout
            if (myinterval) {
                clearTimeout(myinterval);
            }

    
        // }
    }

    showLoading();

    // Call hideLoading when all page resources have finished loading
    window.addEventListener("load", function () {
        // assetsLoaded = true; // Mark that assets have loaded
        hideLoading();
    });
});
