/**
 * (C) 2024. Justin K Kazmierczak. All rights reserved.
 * Subject to The Universe Terms of Service.
 * 
 * Universe App Tools
 * Creates a consistent Universe App Header with options that will display next to it.
 * 
 * Created by Justin K Kazmierczak.
 */
var namespace = "ua.header";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
// var uai = require("../interface/interface.js");

exports.define = {
  namespace: "ua.header",
  title: "Universe App Header",
  description: "A consistent header for apps built with Universe App Tools.",
  fields: {
    "icon": {
      type: "icon", 
      description: "The icon used on the left side of the header."
    }, "title": {
      type: "string",
      description: "The title of the header.",
      required: true
    }, "description": {
      type: "string",
      description: "The description of the header."
    }, "inner": {
      type: "inner",
      description: "The tools to be added to the end of the header such as buttons."
    }
  }
}

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  var header = {
    "n": "div",
    "c": "container-fluid p-0 m-0 ua-header",
    "i": []
  };

  //is their a icon property in options
  if (options.icon) {
    header.i.push({
      "n": "div",
      "c": "text-center p-3 ua-header-icon",
      "i": [{
        "n": "i",
        "c": `bi bi-${options.icon}`,
        "s": "font-size:4rem;"
      }]
    });
  }

  var title = {
    "n": "div",
    "c": "p-0 m-0 ua-header-title-holster",
    "i": [{
      "n": "h2",
      "c": "fs-1 m-0 p-0 d-inline ua-header-title",
      "i": options.title
    }]
  };

  if (options.description) {
    title.i.push({
      "n": "p",
      "c": "text-muted m-0 p-0 d-inline",
      "i": options.description
    });
  }

  header.i.push(title);

  if (options.inner) {
    header.i.push({
      "n": "div",
      "c": "p-3",
      "i": options.inner
    });
  }

 
  return await jsonRender.convertJSONToDOM(header, {
    PassthroughOptions: options
  });

}

/** 
 * 
 * 
 <div class="container-fluid p-0 m-0 ua-header">
    <div class="text-center p-3 ua-header-icon">
        <i class="bi bi-${icon}" style="font-size:4rem;"></i>
    </div>
    <div class="p-0 m-0 ua-header-title-holster">
        <h2 class="fs-1 m-0 p-0 d-inline ua-header-title">${title}</h2>
        <p class="text-muted m-0 p-0 d-inline">${description}</p>
    </div>
    <div class="p-3">
        <button class="btn btn-underline-primary"><i class="bi bi-gear-wide-connected"></i></button>
    </div>  
</div> 
*/
