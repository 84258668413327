/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*
<div class="form-group pb-3">
    <label for="${name}" class="pb-2 fs-4">${title}</label>
    <div class="input-group">
        <div class="input-group-prepend">
            <div class="input-group-text text-color rounded-0 rounded-start"><i class="${icon}"></i></div>
        </div><input id="${name}" class="form-control is-invalid" title="${title}">
    </div>
</div>
*/

var jsonRender = require("../interface/jsonRender.js");
var interface = require("../interface/interface.js");

var define = {
  namespace: "ua.input",
  title: "Form Group: Text Box",
  description: "A form group with a text box.",
  control: true,
  prevalidate: true,
  fields: {
    "name": {
      type: "string",
      description: "The name of the control.",
      required: true
    },
    "title": {
      type: "string",
      description: "The title of the control.",
      required: true
    },
    "icon": {
      type: "string",
      description: "The icon of the control.",
      required: false
    },
    "value": {
      type: "string",
      description: "The value of the control.",
      required: false
    }, "inputClass": {
      type: "string",
      description: "The class of the input."
    }, "inputStyle": {
      type: "string",
      description: "The style of the input."
    }
  },
  passthrough: {
    except: ["inner", "type", "id", "inputclass", "inputstyle"]
  }
}; module.exports.define = define;

 
/**
 * Preparing migration to jsonRender and uae.render();
 * @param {*} options The object to render
 * @returns a ua.code dom element
 */
exports.render = async function (options) {



  var randomName = interface.generateRandomID(12);

  var _input = {
    "n": "input",
    "id": randomName,
    "class": "form-control",
    "title": options.title
  };

  if ("value" in options) {
    _input.value = options.value;
  }

  try {
    // console.log("*** Opt 1", {
    //   options, _input});

      _input = await jsonRender.pass({...options}, {..._input}, ["name", "class", "icon", "style"]);

      // console.log("My new input", myNewInput);

    // _input = await jsonRender.pass(options, _input, ["name", "class", "icon", "style"]);
  } catch (error) {
    // console.log(`ua.input Error: ${error}`);
  }

// console.log("*** Opt 2", JSON.stringify([options, _input], null, 2));

  // console.log("Input", {input, options});

  if ("inputclass" in options) {
    _input.class += " " + options.inputclass;
  }

  // console.log("*** Opt 3", JSON.stringify([options, _input], null, 2));

  if ("inputstyle" in options) {
    _input.style = options.inputstyle;
  }

//   console.log("*** Opt 4", JSON.stringify([options, _input], null, 2));

// console.log("*** Opt 5", JSON.stringify([options, _input], null, 2));


  var element = {
    "n": "div",
    "name": options.name,
    "c": "form-group pb-3",
    "i": [{
      "n": "label",
      "for": randomName,
      "c": "pb-2 fs-4",
      "i": options.title
    }, {
      "n": "div",
      "c": "input-group",
      "i": ""
    }]
  }

  // console.log("*** Opt 6", JSON.stringify([options, _input], null, 2));

  //if icon is provided
  if ("icon" in options) {
    element.i[1].i= [{
      "n": "div",
      "c": "input-group-prepend",
      "i": [{
        "n": "div",
        "c": "input-group-text text-color rounded-0 rounded-start",
        "i": [{
          "n": "i",
          "class": options.icon
        }]
      }]
    }, _input];
  } else {
    element.i[1].i = _input;
  }

  // console.log("*** Opt 7", {options, _input});

  // console.log("ua.input Sending an element", JSON.stringify([element, options], null, 2));

  var domElement = await jsonRender.render(element);

  // console.log("*** Opt 8", options);

  //when the input focus changes validate it
  domElement.querySelector("input").addEventListener("focus", focus);
  domElement.querySelector("input").addEventListener("focusout", focus);

  // console.log("*** Opt 9", options);

  return domElement;

}

async function focus(event) {
  // console.log("focus", event);


  //get the parent uae element
  var parent = this.closest("uae");

    this.classList.remove("is-invalid");
    this.classList.remove("is-valid");

    //if the input is not empty
    if (this.value.trim() != "") {
     
      //check if it's valid
      if (this.checkValidity()) {
        this.classList.add("is-valid");

        if (parent) {
          parent.classList.add("is-valid");
        }

      } else {
        this.classList.add("is-invalid");

        if (parent) {
          parent.classList.add("is-invalid");
        }

      }

    }
}

/**
 * Saves the code from the iframe.
 * Called by UATools.
 * @param {*} name The name of the control.
 * @param {*} control The control to save.
 * @param {*} repo The repo (used to send back), with the prevalidation results.
 * @property {*} repo.success The success object (is this field ready to be saved).
 * @property {*} repo.data The actual data object that will be saved. JSON encodable only (no functions or promises).
 * @property {*} repo.errors The error's applied to the object. Should be an array, can have more than one item.
 * @property {*} repo.errors.input If appliable, the direct input that caused the erorr - it must be an object. If input is not provided the control will be highlighted.
 * @property {*} repo.errors.input.id The id of the input field, if applicable.
 * @property {*} repo.errors.input.name The name of the input field if applicable.
 * @property {*} repo.errors.type The type of error that occured.
 *  - Supports: "validation" - The input or field or control is invalid
 *  - Supports: "thowable" - Processing this field caused a throwable to error out.
 * @property {*} repo.errors.message The message to display to the user.
 * @returns The repo object with the data to save
 */
async function save(name, control, repo) {

  // console.log("Starting save", {
  //   control, repo
  // });


  //get the input from the control and check if it's valid
  var input = control.querySelector("input");


  //if input has a min length but value is empty
  // if (input.minLength > 0 && input.value.trim() == "") {
  //   // console.log("Input invalid", "Input is empty");
  //   repo.success = false;
  //   repo.errors.push({
  //     type: "validation",
  //     message: "This field is required.",
  //     input: {
  //       id: input.id,
  //       name: input.name
  //     }
  //   });
  // }


  if (!(input.checkValidity())) {
    // console.log("Input invalid", input.validationMessage);
    repo.success = false;
    repo.errors.push({
      type: "validation",
      message: input.validationMessage
    });
  }

  repo.data = input.value;

  // console.log("Ending save", {
  //   control, repo
  // });

  return repo;

} module.exports.save = save;

/**
 * Loads the control with data.
 * @param {*} name The name of the control.
 * @param {*} control The control itself (including placeholder tag).
 * @param {*} data The data to load into the control.
 */
async function load(name, control, data) {

  //get the input from the control
  var input = control.querySelector("input");

  //set the value
  input.value = data;

} module.exports.load = load;