/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.getdirections";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
// var uae = require("./templates/ua.element.js");


var required = ["address"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Get Directions",
  description: "A button that opens a dialog showing directions through supported map apps.",
  fields: {
    "address": {
      type: "string", 
      description: "The address to give directions to."
    }, 
    "inner": {
      type: "inner", 
      description: "The contents od the button to open the directions dialog."
    }
  },
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  if ("UATisServer" in window && window.UATisServer) {

   return await jsonRender.generateJsonWrapper(options);

  };


  if (!("inner" in options)) {
    options.inner = [{
        "n": "i",
        "c": "bi bi-geo-alt-fill"
      }, "Get Directions"];
  }

  var button = {
    "n": "button",
    "class": "btn btn-primary",
    "i": options.inner
  };

  button = await jsonRender.render(button, {
    PassthroughOptions: options
  });

  // console.log("The button is: ", {
  //   btn: btn,
  //   btnType: typeof btn,
  //   PassthroughOptions: options
  // });

  button.addEventListener("click", async function(event) {

    var address = event.target.getAttribute("address");
    var modal = {
      "n": "ua.dialog.getdirections",
      "address": address,
    };

    var modal = await jsonRender.render(modal, {
      PassthroughOptions: options
    });

    document.body.appendChild(modal);

  });

  return button;

}

/** 
 * 
 * 
 * 
 * <a href="https://www.google.com/maps/place/16001+Needles+Rd,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/google-maps.png" alt="Google Maps"> Google Maps
                </a>

                <a href="https://maps.apple.com/?address=16001%20Needles%20Drive,+Johnstown,+OH+43031" target="_blank">
                    <img src="../imgs/apple-maps.png" alt="Apple Maps"> Apple Maps
                </a>
 * 
 *
*/
