/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */

var namespace = "ua.fs.video";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var events = require("../../../uam/events.js");
// var uai = require("../interface/interface.js");

//autoplay videos
events.on("interface.afterrender", async function() {

  //add a click event to the video to toggle play/pause
  //use a modern event listener, so your adding one event
  //and not multiple events.
  document.addEventListener("click", function(e) {
    if (e.target.classList.contains("ua-fs-background-play")) {
      if (e.target.paused) {
        e.target.play();
      } else {
        e.target.pause();
      }
    }
  });

  // Select all videos with the specified class
const videos = document.querySelectorAll('.ua-fs-background-video');


// if (entry.target.hasAttribute("fixed")) {
//   if (entry.target.getAttribute("fixed") == "true") {
//     if (entry.isIntersecting) {
//       entry.target.classList.add("video-fixed");
//       // entry.target.classList.remove('d-none');
//     } else {
//       entry.target.classList.remove("video-fixed");
//       // entry.target.classList.add('d-none');
//     }
//   }
// }


window.requestToPlayDiv = jsonRender.render({
  "id": "requestToPlayDiv",
  "n": "holster",
  "c": "vh-100 vw-100 position-fixed d-flex justify-content-center align-items-center bg-blur-dark",
  "style": "top:0px; left:0px; background-color: rgba(0,0,0,.5); z-index: 1000;",
  "i": {
      "n": "div",
      "c": "d-block",
      "i": [{
          "n": "i",
          "c": "bi bi-play-circle d-block fs-1 text-center",
          "i": ""
      },"Press to play."]
  }
});

// Create an Intersection Observer for each video
videos.forEach(video => {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {

            if (entry.isIntersecting) {
              window.storage[`${namespace}.videoInView`] = entry.target;
                // window.onVid = entry.target;
                // console.log("video in view", {
                //   onVid: window.onVid
                // });
              
              video.play().catch(error => {
                  //playback is being prevented, //show fullscreen button
                  // video.setAttribute("controls", "");
                    // console.error('Video playback failed:', error);

                    try {
                      if ("requestToPlayDiv" in window) {

                          if (document.querySelector("#requestToPlayDiv").contains("d-none")) {
                            //do nothing because it is already done
                          } else {

                            window.requestToPlayDiv.addEventListener("click", function(e) {

                              if (!(window.requestToPlayDiv.classList.contains("d-none"))) {
                                RemoveRequestToPlayDiv();
                                video.play();
                              }
                            });

                            document.body.appendChild(window.requestToPlayDiv);

                          }
                      }
                    } catch (error) {
                      // the element may not exist
                    }
                                  


              });
            } else {
                video.pause();
            }
        });
    }, {
        threshold: 0.01
    }); // Adjust threshold as needed

    observer.observe(video);
  });

});


function RemoveRequestToPlayDiv() {
  if ("requestToPlayDiv" in window) {
  // if (!(window.requestToPlayDiv.classList.contains("d-none"))) {
    //using the id of window.requesttoplaydiv, make all and any instances of them go away
    var requestToPlayDivs = document.querySelectorAll("#requestToPlayDiv");
    for (const requestToPlayDiv of requestToPlayDivs) {
      requestToPlayDiv.classList.add("d-none");
    }
  // }
  }
}


var checkIfAnyVideoPlayingInterval = null;

function checkIfAnyVideoPlaying() {
  // alert("hi");
  var videos = document.querySelectorAll('video');
    for (const video of videos) {
        if (!video.paused) {
            clearInterval(checkIfAnyVideoPlayingInterval);
            // alert("video is playing");
            RemoveRequestToPlayDiv();
            return;
        }
    }
}

// setTimeout(checkIfAnyVideoPlaying, 3000);


//Hide and show ua-fs-background-image;
events.on("interface.afterrender", async function() {

  checkIfAnyVideoPlayingInterval = setInterval(checkIfAnyVideoPlaying, 100);

  // Select all videos with the specified class
  const vHolsters = document.querySelectorAll('.ua-fs-background-video-holster');

  // Create an Intersection Observer for each "fixed image" to hide and show
  vHolsters.forEach(vholst => {
      const observer2 = new IntersectionObserver(entries => {
          entries.forEach(entry => {
           var video = entry.target.querySelector(".ua-fs-background-video")
            if (video.hasAttribute("fixed")) {
              if (video.getAttribute("fixed") == "true") {
                if (entry.isIntersecting) {
                  // console.log("video is visible", entry.target);
                  video.classList.remove("d-none");
                  // entry.target.classList.remove('d-none');
                } else {
                  // console.log("video is not visible", entry.target);
                  video.classList.add("d-none");
                  // entry.target.classList.add('d-none');
                }
              }
            }
          });
      }, {
          threshold: 0.01
      }); // Adjust threshold as needed

      observer2.observe(vholst);
    });

});


var required = [];
exports.required = required;



exports.define = {
  namespace: namespace,
  fields: [{
    "type": {
      type: "string",
      description: "The type of fs fixed background to use. Defaults to image."
    }, "src": {
      type: "string",
      description: "The source of the image or video."
    // }, "inner": {
    //   type: "inner",
    //   description: "The inner items to display on top of the background."
    }, "poster":  {
      type: "uri",
      description: "The poster image to display before the video plays."
    }
  }],
  required: required
} 



/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} ele The json element to render the footnote on.
  * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  // var options = uae.GetOptions(json, required);

    var bg = {
      n: "video",
      c: "ua-fs-background ua-fs-background-video",
      playsinline: "",
      muted: "",
      loop: "",
      i: {
        n: "source",
        src: options.src,
        type: "video/mp4"
      }
    }

    var playButton = {
      n: "button",
      c: "ua-fs-background-play"
    };

    var article = {
      n: "article",
      c: "ua-fs-background-video-holster",
      i: [bg]
    };
  
    
    if ("inner" in options) {
      article.i.push(options.inner);
    }
  
    if ("poster" in options) {
      bg.poster = options.poster;
    }
  
    if ("fixed" in options) {
      if (!(options.fixed)) {
        //its false
      } else {
        bg.c += " video-fixed";
        bg.fixed = options.fixed;
        // article.fixed = options.fixed;
      }
    }

  return await jsonRender.render(article, {
    PassthroughOptions: options
  });
  
};

/**
 * Plays the video that is registered in view.
 */
function OnFirstInteraction_PlayVideoInView() {

  //remove the listener
  //document.body.removeEventListener("click", OnFirstInteraction_PlayVideoInView);

  // var storage = require("../interface/storage.js");
  var onVid = window.storage[`${namespace}.videoInView`];

  if (!onVid) {
    /////////////console.warn("No video in view");
    return;
  }

  if (onVid.paused) {
    onVid.play();
  } 

} events.on("interface.oninteraction", OnFirstInteraction_PlayVideoInView);