/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.navbar";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var events = require("../../../uam/events.js");
// var uae = require("./templates/ua.element.js");

var uaNavBarItem = require("./ua.navbar.item.js");

var required = ["inner"]; 
exports.required = required;

exports.define = {
  namespace: namespace,
  title: "Enhanced Navigation Bar",
  description: "A beutiful navigation bar for your app. If you use the fixed-top class, it will automatically add margin to the top of the body (this may overide other settings) so it doesn't overlap the content.",
  fields: {
    "first": {
      type: "inner", 
      description: "The first items of the navigation bar, these items will exapnd from the left of the navbar.."
    }, "fixed": {
      type: "boolean",
      description: "If the navbar should be fixed to the top of the page.",
      default: true
    }, items: {
      type: "inner",
      namespaces: ["ua.navbar.items"],
      description: "The items to add to the navbar."
    }, "brand": {
      type: "boolean",
      description: "If the navbar should have add the default brand.",
      default: true
    }, "brandInner": {
      type: "inner",
      description: "The inner content of the brand (in addition to logo)."
    },last: {
      type: "inner",
      description: "The last items of the navigation bar, these items will exapnd from the right of the navbar."
    }, "MenyIconClass": {
      type: "string",
      description: "The class to use for the menu icon.",
      default: ""
    }
  },
  required: required
} 

/** 
 * If I have one fixed-top navbar, set the body margin-top to it's height so it doesn't overlap the content.
*/
events.on("interface.afterrender", async function() {

  var nav = document.querySelector(".navbar.fixed-top");
  if (nav) {

    //get the current padding top of the body
    // if (!document.body.style.paddingTop) {
    //   document.body.style.paddingTop = "0px";
    // }

    if (!(nav.hasAttribute("do-not-margin-body"))) {
      document.body.style.marginTop =  nav.offsetHeight + "px";
    }
 
  }

});

/**
 * Creates the navigation items from an array or object.
 * @param {*} items 
 * @returns the navigation items.
 */
function createNavItems(items) {

  if (!Array.isArray(items)) {
    return createNavItem(items);
  } 

  var navItems = [];
  for (var i = 0; i < items.length; i++) {

    // console.log(`Creating nav item from ua.navbar.item:`, items[i]);

    if (items[i].namespace == "ua.navbar.item") {    
      navItems.push(createNavItem(CreateLinkFromUaNavbarItem(items[i])));
      continue;
    }

    navItems.push(createNavItem(items[i]));
  }

  return navItems;

}

/**
 * Create a navigation item for the navbar
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavItem(item) {
  return {
      n: "li",
      c: "nav-item",
      i: item
  }
}

/**
 * Creates a navigation group for the navbar (ul).
 * @param {*} item The item to create the nav item for.
 * @returns JSON object of a nav item.
 */
function createNavGroup(item) {
  return {
      n: "ul",
      c: "navbar-nav m-auto d-flex justify-content-between align-items-center text-center",
      i: item
  }
}


function CreateLinkFromUaNavbarItem(uaNavBarItem) {

  var itemEle = [];
  if ("icon" in uaNavBarItem) {
    itemEle.push({
      n: "i",
      c: `bi bi-${uaNavBarItem.icon}`
    });

    //add the extra space for display purposes
    if (!("hideText" in uaNavBarItem && uaNavBarItem.hideText)) itemEle.push("&nbsp;");
  }


  if (!("hideText" in uaNavBarItem && uaNavBarItem.hideText)) {
    if ("quickName" in uaNavBarItem) {
      itemEle.push(uaNavBarItem.quickName);
    } else {
      itemEle.push(uaNavBarItem.title);
    }
  }

  var a =  {
    n: "a",
    c: "nav-link",
    t: uaNavBarItem.title,
    href: uaNavBarItem.src,
    i: itemEle
  };

  if ("target" in uaNavBarItem) {
    a.target = uaNavBarItem.target;
  }

  return a;

}

var lastClass = " justify-content-end";

/**
 * We'll add the footnote to the footnotes for the page, and output the corresponding number
 * @param {*} options The json element to render the footnote on.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

  //defaults
  if (!("brand" in options)) {
    options.brand = true;
  }


  // var options = json; 

  // var expandable = false;

  // if ("expandable" in options) {
    // expandable = options.expandable.trim().toLowerCase();
  // }

  //create the navigation groups
  var inner = [];

  if ("brand" in options && options.brand) {
    var brand =
    {
      "n": "a",
      "c": "navbar-brand mybrand d-inline-block",
      "style": "min-width:3.2rem; height:3.2rem; padding:0; margin:0;",
      "href": "/",
      "title": "home",
      "inner": "&nbsp;"
    };

    if ("brandInner" in options) {
      brand.inner = options.brandInner;
    }

    inner.push(brand);

  }
  
  if ("first" in options) {
    var first = createNavItems(options.first);
    first = createNavGroup(first);

    // if (expandable == "first") {
      first.c += " flex-fill justify-content-around";
    // }

    inner.push(first);
  }


  // get all pinned items in the list
  var pinned = [];
  var unpinned = [];

  if ("items" in options) {

    // console.log(`Items:`, options.items);
  
    for (var i = 0; i < options.items.length; i++) {
      var item = options.items[i];
      if ("pin" in item && item.pin) {
        pinned.push(item);
      } else {
        unpinned.push(item);
      }
    }

    // console.log(`Pinned: ${pinned.length}, Unpinned: ${unpinned.length}`);

  }

  // console.log(`Pinned: ${pinned.length}, Unpinned: ${unpinned.length}`);

  // var navItems = [];
  // for (var i = 0; i < pinned.length; i++) {

  //   // {
  //   //   "n": "a",
  //   //   "c": "nav-link",
  //   //   "href": "http://192.168.0.53:8517/travel",
  //   //   "i": "about"
  //   // }

  //   // console.log(`Pinned item:`, pinned[i])''

  //   // console.log(`Nav item:`, item);
  //   // navItems.push(createNavItems(pinned[i]));

  // }

  var innerNav = createNavItems(pinned);
  innerNav = createNavGroup(innerNav);

  // if (expandable == "inner") {
    innerNav.c += " flex-fill justify-content-around nav-centermass ps-2 pe-2";
  // }

  inner.push(innerNav);


  // if ("inner" in options) {
  //   var innerNav = createNavItems(options.inner);
  //   innerNav = createNavGroup(innerNav);

  //   if (expandable == "inner") {
  //     innerNav.c += " flex-fill justify-content-around";
  //   }

  //   inner.push(innerNav);
  // }

  var menuItem = {
    n: "button",
    c: "button btn btn-text-white ua-navbar-item-controls-menu",
    i: {
      n: "i",
      c: "bi bi-three-dots-vertical"
    }
  }

  if ("menuIconClass" in options) {
    menuItem.c += ` ${options.menuIconClass}`;
  }

  var menu = uaNavBarItem.createNavMenu(options.items, options.inner);
  menuItem["ua-navbar-menu"] = menu.id;


  if ("last" in options) {

    //if options.last is not an array, make it an array
    if (!Array.isArray(options.last)) {
      options.last = [options.last];
    }


    var last = createNavItems([...options.last, menuItem]);
    last = createNavGroup(last);

    // if (expandable == "last") {
      last.c += lastClass;
    // }

    inner.push(last);
  } else {
    var last = createNavItems([menuItem]);
    last = createNavGroup(last);

    // if (expandable == "last") {
      last.c += lastClass;
    // }

    inner.push(last);
  }

  //append the navivigation items
  var nav = {
    n: "nav",
    c: "ua-navbar navbar navbar-expand",
    "aria-label": "Navigation",
    i: [{
      n: "div",
      c: "container-fluid d-flex",
      i: inner
    }]
  };


  nav = await jsonRender.pass(options, nav, ["namespace", "items", "first", "last", "brand", "brandInner", "inner"]);

  // console.log(`Nav:`, nav);

  return await jsonRender.convertJSONToDOM([nav, menu]);

}

/** 
 * 
 * <nav id="navMain" class="navbar navbar-expand navbar-dark bg-blur-dark fixed-top" aria-label="Navigation">
        <div class="container">
<ul class="navbar-nav">
    <a class="navbar-brand mybrand d-inline-block" style="width:4rem; height:4rem;" href="/" title="home"></a>
</ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#home">Home</a>
                </li>
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#developers">Developers</a>
              </li>
<li class="nav-item">
    
</li>
            </ul>
<ul class="navbar-nav">
    <li class="nav-item"><button class="btn btn-sm btn-info btn-hover-white text-uppercase ls-1" type="submit">Join the Beta</button></li>
</ul>
            
            
            
            
        </div>
      </nav>
*/
