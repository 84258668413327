/* {"ns":"universe.module.sanitize", "v":1, "i":false} */
/**
 * Santization helps report common errors with a parameters.
 * 
 * This component may not be reversed engineered for hacking or abuse
 * of The EGT Universe, The Universe, or third-party apps.
 * 
 * Written for:
 * all components that use modularity
 * 
 * Justin K Kazmierczak
 * All Rights Reserved.
 * 
 * May be subject to The Universe Terms of Service.
 **/

var report = true;

/**
 * Validates that a value is of specified type.
 * You may also test for an array
 * @param {*} must The typeof objct to test for.
 */
function s(must, value) {
    if (must === "array") {
        if (Array.isArray(value)) {
            if (report) {console.log("I am a safe array");}
            return true;
        } else {
            if (report) {console.log("I'm not a safe array");}
            return false;
        }
    } else if (typeof value === must) {
       // console.log(`I'm ${typeof value}`);
        return true;
    } else {
        //console.log(`I'm not ${must} instead I am ${typeof value}`);
        return false;
    }
}

/**
 * Verifies an object is not null and undefined.
 * @param {*} value The valye to test.
 */
function n(value) {
    
    var okay = false;
    if (typeof value === undefined) {
        if (report) {console.log("my type is compared to undefined");}
    } else if(value == null) {
        if (report) {console.log("I am null")}
    } else {
        okay = true;
    }

    return okay;
}

/**
 * Validates a value based on the typeof suppied.
 * Performs a "not null|undefined" test.
 * @param {*} must The typeof object to test for.
 * @param {*} value The value to test.
 */
function main(must, value) {
    var okay = false;
    okay = n(value); //am I null

    if (okay) {
        okay = s(must, value);
    }

    return okay
}

/**
 * Validates a value to a string, and conforms it to lowercase, and trims
 * @param {*} value 
 */
function l(value, _trim = true) {
    var okay = false;
    okay = main("string", value);

    //console.log("ok is", okay);

    if (okay) {
        //trim item
        if (_trim) {
            return value.trim().toLowerCase();
        } else {
            return value.toLowerCase();
        }

    } else {
        throw new Error("The provided string is invalid.");
    }
}

/**
 * Test's the santization santize.main() but reports it's status to the logger.
 * This is unsafe as the logger may throw an exception.
 * @param {*} log The Logger object. @deprecated
 * @param {*} must a string to test typeof (can also test for array)
 * @param {*} value the value to perform the tests against
 * @returns true if the value passes the tests, false if it fails.
 */
function TestSantizationWithLogger(log, must, value) {
    // console.log("logger is", log);
    // log.i(this._proc, this._st, "start", `Testing object with type of ${typeof value} for type of ${must}`, must, value);
    if (!main(must, value)) {
        console.error(`The paramater failed sanitization typeof ${must}, instead was typeof ${typeof value} (simple).`, {
            must: must,
            value: value
        });
        // log.error(this._proc, this._st, "Santize", `The paramater failed sanitization typeof ${must}, instead was typeof ${typeof value} (simple).`, value); 
        return false;
    } else {
        return true;
    }
}

module.exports = main;
module.exports.s = s;
module.exports.n = n;
module.exports.l = l;
module.exports.log = TestSantizationWithLogger;