/**
 * Converted to New Approach (json render)
 * 
 * Universe App Tools
 * Adds a footnote to the page.
 * 
 * Created by Justin K Kazmierczak.
 */


var namespace = "ua.a.incenter";
exports.namespace = namespace;

var jsonRender = require("../interface/jsonRender.js");
var uai = require("../interface/interface.js");
var events = require("../../../uam/events.js");

var required = [];
exports.required = required;

exports.define = {
    namespace: namespace,
    fields: [{
        "goto": {
            type: "id",
            description: "The ID to the anchor or element to scroll to the center of the screen."
        }
    }],
    required: required
}

/**
 * Adds a anchor link tag to the page.
 * @param {*} options The json objects and properties to render.
 * @returns The final renderalbe object, dom or ua/json.
 */
exports.render = async function (options) {

    var anc = {
        n: "a",
        c: "ua-a-incenter",
        href: options.goto
    };

    if (options.inner) {
        anc.i = options.inner;
    }

    return await jsonRender.convertJSONToDOM(
        anc, {
        PassthroughOptions: options
    });

}

/**
 * Configures the anchor tag click event to scroll to the center of the screen.
 */
events.on("interface.afterrender", async function () {

    /** create the highlight overlay */
    CreateHighlightOverlay();

    var scrollToLinks = document.querySelectorAll('.ua-a-incenter');

    scrollToLinks.forEach(link => {

        link.addEventListener('click', function (event) {
 


            var targetId = this.getAttribute('href');

            var justHash = targetId.startsWith("#");

            if (!justHash) {
                //check to make sure the href is on the same page (make sure the window.location doesn't include #)
                if (window.location.href.includes("#")) {
                    var currentUrl = window.location.href.split("#")[0];
                    var targetUrl = targetId.split("#")[0];
                    if (currentUrl != targetUrl) {
                        // console.log("not the same page", {
                        //     currentUrl,
                        //     targetUrl,
                        //     justHash
                        // });
                        //perform default action
                        return;
                    }
                }
            }

            //get only the lookup at the end of the url in href
            if (targetId.includes("#")) {
                targetId = targetId.split("#").pop();
            }

            var targetElement = document.getElementById(targetId);

            // console.log("scroll to center", {
            //     targetId,
            //     targetElement,
            //     link
            // });

            if (targetElement) {
                
                event.preventDefault();
                
                //is the target an a tag?
                if (targetId == "a") {
                    HighlightAnchor(targetElement);
                    // return;
                } else {

                    CenterScrollToElement(targetElement);

                    //remove any ua-a-incenter-highlight classes
                    var highlightElements = document.querySelectorAll(".ua-a-incenter-highlight");
                    highlightElements.forEach(element => {
                        element.classList.remove("ua-a-incenter-highlight");
                    });

                    //add the highlight class to the target element
                    setTimeout(() => {
                        targetElement.classList.add("ua-a-incenter-highlight");
                    }, 150);
                    // return;
                }

            }

            // console.log("not scrolling", {
            //     targetId,
            //     targetElement,
            //     link
            // });

            //perform defualt action
            return;

        });
    });

});

/**
 * Creates the highlight overlay.
 */
function CreateHighlightOverlay() {

    //create the highlighter and it's pre-animate overlay
    var scrollHighlighter = document.getElementById("ua-a-incenter-overlay");
    if (!(scrollHighlighter)) {
        scrollHighlighter = document.createElement("div");
        scrollHighlighter.id = "ua-a-incenter-overlay";
        scrollHighlighter.style.position = "fixed";
        scrollHighlighter.style.top = "30vh";
        scrollHighlighter.style.left = "0";
        scrollHighlighter.style.right = "0";
        scrollHighlighter.style.zIndex = "900";
        scrollHighlighter.style.pointerEvents = "none";
        scrollHighlighter.style.display = "none";

        scrollHighlighter.innerHTML = `
    
                    <div class="border border-5 border-primary rounded vh-30"></div>
                `; //            <div class="bg-blur-50-black vh-50"></div>

        document.body.appendChild(scrollHighlighter);
    }

    var scrollHighlighter2 = document.getElementById("ua-a-incenter-overlay-pre-animate");
    if (!(scrollHighlighter2)) {

        scrollHighlighter2 = document.createElement("div");
        scrollHighlighter2.id = "ua-a-incenter-overlay-pre-animate";
        scrollHighlighter2.style.position = "fixed";
        scrollHighlighter2.style.top = "0";
        scrollHighlighter2.style.left = "0";
        scrollHighlighter2.style.right = "0";
        scrollHighlighter2.style.zIndex = "900";
        scrollHighlighter2.style.pointerEvents = "none";
        scrollHighlighter2.style.display = "none";
        scrollHighlighter2.classList.add("bg-blur-black");
        scrollHighlighter2.classList.add("vh-100");

        document.body.appendChild(scrollHighlighter2);
    }

}

async function CenterScrollToElement(targetElement) {
    var isBeforeCenter = false;
    // var scrollHighlighter = document.getElementById("ua-a-incenter-overlay");
    var scrollHighlighter2 = document.getElementById("ua-a-incenter-overlay-pre-animate");
    // var elementOffset = targetElement.getBoundingClientRect().top;

    // //is the element above the center of the screen?
    // if (elementOffset < window.innerHeight / 2) {
    //     elementOffset = elementOffset - window.innerHeight / 2;
    //     isBeforeCenter = true;
    // } else {
    //     elementOffset = elementOffset - (window.innerHeight - targetElement.clientHeight) / 2;
    //     // scrollHighlighter.classList.remove("vh-100");
    //     // scrollHighlighter.style.top = "30vh";
    // }

    // // var offset = elementOffset - (window.innerHeight - targetElement.clientHeight + 40) / 2;
    // // get heigt of the element and divide it by 2
    
    // window.scrollTo({
    //     top: window.scrollY + elementOffset,
    //     behavior: 'smooth',
    //     //make sure it lasts .5 seconds
    //     duration: 500
    // });


    //Scrolls the center of the object to the center of the view port
    // var elementRect = targetElement.getBoundingClientRect();
    // var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // // Calculate the vertical scroll position to center the element
    // var scrollPosition = elementRect.top + window.scrollY - (viewportHeight / 2) + (elementRect.height / 2);

    // // Scroll to the calculated position
    // window.scrollTo({
    //     top: scrollPosition,
    //     behavior: 'smooth' // You can change this to 'auto' for instant scrolling
    // });
    
    // //scrols the top of the object to the center of the view port
    // var elementRect = targetElement.getBoundingClientRect();
    // var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // // Calculate the vertical scroll position to align the top of the element with the center
    // var scrollPosition = elementRect.top + window.scrollY - (viewportHeight / 2);

    // // Scroll to the calculated position
    // window.scrollTo({
    //     top: scrollPosition,
    //     behavior: 'smooth' // You can change this to 'auto' for instant scrolling
    // });

    // Scrolls the top of the object to the top of the view port with a 25% buffer, while accounting for padding and margin
    var elementRect = targetElement.getBoundingClientRect();
    var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

    // Calculate the vertical scroll position considering margin and padding
    var scrollPosition = elementRect.top +
        window.scrollY -
        (viewportHeight / 4) +
        targetElement.clientTop; // Add the top padding

    // Scroll to the calculated position
    window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth' // You can change this to 'auto' for instant scrolling
    });

    scrollHighlighter2.style.display = "block";

    setTimeout(() => {
        scrollHighlighter2.style.display = "none";
        // scrollHighlighter.style.display = "block";
        // scrollHighlighter.classList.add("blink-fade");
        
    }, 500);

    return true;
}

async function HighlightAnchor(targetElement) {

    if (targetElement) {

        var scrollHighlighter = document.getElementById("ua-a-incenter-overlay");
        var scrollHighlighter2 = document.getElementById("ua-a-incenter-overlay-pre-animate");

        var showHighlighter = CenterScrollToElement(targetElement);

        if (showHighlighter) {
            scrollHighlighter2.style.display = "block";

            //show the highlighter after the scroll
            setTimeout(() => {
                scrollHighlighter2.style.display = "none";
                scrollHighlighter.style.display = "block";
                scrollHighlighter.classList.add("blink-fade");
                setTimeout(() => {
                    scrollHighlighter.style.display = "none";
                    scrollHighlighter.classList.remove("blink-fade");
                }, 1000);
            }, 500);
        }
    }
}

/**
 * 
 <div id="ua-a-incenter-overlay" style="position:fixed;top:0;left:0; right:0;z-index:900">
<div class="bg-blur-50-black vh-50"></div>
    <div class="border border-5 border-primary rounded vh-50"></div>
        </div>
 */