
    /*
    * Auto Build of Registry
    */

    var registry = require("../../uam/registry.js")("ua.objects");
    
    registry.add(require("./animations/ua.a.count.js"));
    registry.add(require("./animations/ua.a.typeon.js"));
    registry.add(require("./controls/formgroup.address.js"));
    registry.add(require("./controls/formgroup.newpassword.js"));
    registry.add(require("./controls/formgroup.text.js"));
    registry.add(require("./controls/text.js"));
    registry.add(require("./elements/lottie.dot.js"));
    registry.add(require("./elements/pages.blog.js"));
    registry.add(require("./elements/pages.page.js"));
    registry.add(require("./elements/repeat.js"));
    registry.add(require("./elements/ua.a.incenter.js"));
    registry.add(require("./elements/ua.alert.js"));
    registry.add(require("./elements/ua.code.js"));
    registry.add(require("./elements/ua.dialog.getdirections.js"));
    registry.add(require("./elements/ua.e.alert.js"));
    registry.add(require("./elements/ua.e.image.js"));
    registry.add(require("./elements/ua.e.nav.tabs.js"));
    registry.add(require("./elements/ua.element.error.js"));
    registry.add(require("./elements/ua.element.scripts.js"));
    registry.add(require("./elements/ua.footnote.js"));
    registry.add(require("./elements/ua.fs.center.js"));
    registry.add(require("./elements/ua.fs.image.js"));
    registry.add(require("./elements/ua.fs.scrollup.js"));
    registry.add(require("./elements/ua.fs.sidebyside.sticky.js"));
    registry.add(require("./elements/ua.fs.video.js"));
    registry.add(require("./elements/ua.hcard.simple.js"));
    registry.add(require("./elements/ua.input.js"));
    registry.add(require("./elements/ua.list.js"));
    registry.add(require("./elements/ua.nav.js"));
    registry.add(require("./elements/ua.navbar.js"));
    registry.add(require("./elements/ua.platform.video.js"));
    registry.add(require("./elements/ua.scroll.totop.js"));
    registry.add(require("./elements/ua.source.js"));
    registry.add(require("./elements/ua.ts.js"));
    registry.add(require("./animations/ua.a.conic.js"));
    registry.add(require("./animations/ua.a.fade50.js"));
    registry.add(require("./controls/code.js"));
    registry.add(require("./controls/formgroup.email.js"));
    registry.add(require("./controls/formgroup.password.js"));
    registry.add(require("./controls/switch.js"));
    registry.add(require("./elements/chart.js.js"));
    registry.add(require("./elements/lottie.player.js"));
    registry.add(require("./elements/pages.meta.js"));
    registry.add(require("./elements/pages.preview.js"));
    registry.add(require("./elements/row.js"));
    registry.add(require("./elements/ua.accordion.js"));
    registry.add(require("./elements/ua.card.simple.js"));
    registry.add(require("./elements/ua.devmode.js"));
    registry.add(require("./elements/ua.dialog.js"));
    registry.add(require("./elements/ua.e.header.js"));
    registry.add(require("./elements/ua.e.listgroup.js"));
    registry.add(require("./elements/ua.e.paragraph.js"));
    registry.add(require("./elements/ua.element.placeholder.js"));
    registry.add(require("./elements/ua.element.serverNotSupported.js"));
    registry.add(require("./elements/ua.footnotes.js"));
    registry.add(require("./elements/ua.fs.col.js"));
    registry.add(require("./elements/ua.fs.module.js"));
    registry.add(require("./elements/ua.fs.sidebyside.image.js"));
    registry.add(require("./elements/ua.fs.simple.js"));
    registry.add(require("./elements/ua.getdirections.js"));
    registry.add(require("./elements/ua.header.js"));
    registry.add(require("./elements/ua.list.item.js"));
    registry.add(require("./elements/ua.marquee.js"));
    registry.add(require("./elements/ua.navbar.item.js"));
    registry.add(require("./elements/ua.notification.icon.js"));
    registry.add(require("./elements/ua.quick.bio.js"));
    registry.add(require("./elements/ua.server.notSupported.js"));
    registry.add(require("./elements/ua.t.js"));
    module.exports = registry;
    