var f = require("../scripts/f.js");
var uae = require("./templates/ua.element.js");

var namespace = "ua.ts";
exports.namespace = namespace;

var required = ["inner"]; 

exports.render = function (ele) {
   //var date = new Date($(this).attr("fromserver"));
   var ele = document.createElement("span");
   var date = new Date(ele.inner);

   var rtn = f.uaMonthNames[date.getMonth()] + " "
      + date.getDate() + ", " + date.getFullYear() + " ";

   if (date.getHours() > 12) {
     rtn = rtn + (date.getHours() - 12) + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + " PM";
   } else {
     rtn = rtn + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + " AM";
   }

  ele.innerText = rtn;

  rtn = rtn + " " + f.getTimezoneName(date);
  ele.setAttribute("data-toggle", "tooltip");
  ele.setAttribute("title", rtn);
  // uae.SetRendered(ele);

  return ele;

};


var define = {
  namespace: namespace,
  title: "Timestamp",
  description: `A universal timestamp element that shows a database date to the end user.`,
  fields: [{
    "inner": {
      type: "date", 
      description: "The database date to convert to the proper time."
    }
  }],
  required: required
}; module.exports.define = define;