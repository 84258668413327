/**
 * Universe App Tools
 * Application tools for creating unified universe apps.
 * 
 * Created by Justin K Kazmierczak.
 */

/*

<!doctype html>
<head>
    <meta charset="utf8">
    <style type="text/css">
    body {
        padding:0px;
        margin:0px;
        background: #171b1f;
    }
    doc {
        display:none;
    }
   </style>
</head>
<!-- <doc type="javascript">
function test() {
    return false;
}
</doc> -->
<doc type="json">
[
{
    "this": false;
    "this": false
}
]

</doc>
<script src="editor.bundle.js"></script>
*/

var f = require("../scripts/f.js");
var config = require("../config.js");
var uac = require("./templates/ua.control.js");
var uae = require("../elements/templates/ua.element.js");
var jsonRender = require("../interface/jsonRender.js");
// const { lastIndexOf } = require("../registry.js");

var namespace = "ua.c.code";
module.exports.namespace = namespace;

var required = ["type"];

var except = ["inner", "type", "id"];

exports.define = {
  namespace: namespace,
  fields: {
    "type": {
      type: "list", 
      options: ["javascript", "json", "html", "css"],
      description: "The type of code to render."
    },
    "inner": {
      type: "string",
      description: "The inner code to render."
    }
  },
  required: required
} 


exports.render = async function (ele) {
 var options = uac.GetOptions(ele, required);

 if ("UATisServer" in window && window.UATisServer) {

    // console.info("Server side blog preview not implemented yet.", options);

    var onlyClientSideRender = {
      "n": "json",
      "type": "ua/interface",
      // "render": "no-server",
      "i": JSON.stringify(options, null, 0) + ""
    };

    // console.info("Only client side render", onlyClientSideRender);
    return await jsonRender.render(onlyClientSideRender);

  }

 //already appended
 if (!(options.uarendered === true)) {

  var iframe = document.createElement("iframe");

  var html = `<!doctype html>
  <head>
      <meta charset="utf8">
      <style type="text/css">
      body {
          padding:0px;
          margin:0px;
          background: #171b1f;
      }
     </style>
  </head>
  <body>
  <doc type="${options.type}">${options.inner}</doc>
  <script src="${f.FullUrl()}${config.location}code.editor.bundle.js"></script>
  </body>
  </html>`;
  console.log("Encoding to frame", html);

  var blob = new Blob([html], {type: 'text/html'});
  iframe.src = URL.createObjectURL(blob);
  iframe.classList.add("card");
  iframe.classList.add("iframedoc");

  // add: allow-popups - if you need thems
  iframe.setAttribute("sandbox", "allow-same-origin allow-popups allow-scripts allow-forms");
  iframe = uac.PassOptions(options, iframe, except);

  // iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
  // This does not work if the iframe isn't a member of the
  // body doucment.
  //
  // iframe.contentWindow.document.open();
  // iframe.contentWindow.document.write(html);
  // iframe.contentWindow.document.close();

  iframe.addEventListener("prepare", Prepare);
  return uac.Render_FinalStep(iframe);

 }

}

function Prepare(event) {
  var control = this;

  var iframe = v.querySelector("iframe");
  var data = iframe.contentWindow.postMessage("reqsrc", '*');
  log.info("Code manager response.", data);

  var jsonexport = {};

  try {
    if (data.success == false) {
      var e = "The Code could not be saved."
      jsonexport = uac.EncodeValidationError(e, false, control)
      log.info(e, error);
    } else {
      jsonexport = uac.PrepareOutput(control, data.source);
    }
  
  } catch (error) {
    var e = "Failed to get data from the code manager."
    jsonexport = uac.EncodeValidationError(e, false, control)
    log.info(e, error);
  }

  uac.StoreValue(control, jsonexport);

}

//copy from formgroup
function PassAtributes(ele, input, _except = []) {

  var except = ["class", "style"];
  except = except.concat(_except);

  return uae.PassthroughOptions(input, ele, except);

}
